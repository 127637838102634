.crm-main-container .sub-breadcrumb ul {
    padding: 15px 15px 0 80px;
    display: flex;
    flex-flow: row;
}

.breadcrumb-page {
    padding: 0px 80px 20px 80px;
}

.import-image {
    width: 100px;
    height: 80px;
    text-align: center;
    justify-content: center;
}

.import-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bolder;
    font-size: 28px;
    line-height: 58px;
    color: #212121;
}

.import-sub-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 132%;
    width: 250px;
    overflow: auto;
    color: rgba(33, 33, 33, 0.75);
}

.import-download-text {
    text-decoration: none;
    font-size: small;
    cursor: pointer;
}

.select-file {
    border: 1px solid #2121211F;
    border-radius: 10px;
    height: 200px;
    line-height: 1.5rem;
}

.select-file>div {
    cursor: pointer;
}

.my-div {
    text-align: left;
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 10px 10px 0 10px;
    margin: 10px;
    border: 1px solid #2121211F;
    border-radius: 10px;
    position: relative;
}

.my-button {
    border: 0;
    background: #45BCD2;
    width: 100px;
    padding: 10px 0;
    /* transform: translateY(15px); */
    position: absolute;
    float: right;
    right: 25px;
    bottom: -20px;
}

.breadcrumb2 {
    margin-bottom: 25px;
    /* padding: 5px; */
}

.breadcrumb2 .row {
    align-items: center;
    padding: 2px;
    height: 40px;
}

.breadcrumb2 .row .col {
    background: #fff;
    height: 30px;
    border: none;
    padding: 0;
}

.breadcrumb2 .row .col .form-select {
    line-height: 1rem !important;
}


.crm-main-container hr {
    margin: 0;
}

.submit-btn,
.submit-btn:hover,
.submit-btn::after {
    color: white;
    background-color: #45BCD2 !important;
    border-color: #45BCD2 !important;
}

.template-body {
    width: 100%;
    height: 300px;
    overflow: auto;
}