.asset-list-card {
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 5px;
}

.asset-list-card .table,
.asset-list-card td {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  vertical-align: middle;
}

.asset-list-card .list-card-img {
  width: 88px;
  height: 50px;
}

.list-card-tags div {
  background-color: #f8f8f8;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  float: left;
  text-transform: uppercase;
}

.show-entry select{
    margin: 0px 15px;
    font-size: 15px;
    padding: 2px;
    padding-left: 15px;
    border: 2px solid #45bcd2;
    color: #45bcd2;
}

.show-entry span{
  font-weight: normal;
}

.new-card-tag {
  color: #d6b922;
  border: 1px solid #d6b922;
  background-color: rgba(33, 33, 33, 0.75) !important;
  border-bottom: 0;
}

.asset-list-card .card-title {
  font-size: 10px;
  color: rgba(33, 33, 33, 0.75);
  margin-bottom: 0;
}

.asset-list-card .card-title span {
  color: rgba(33, 33, 33, 0.5);
  margin-left: 5px;
}

.list-card-tag {
  width: 10.5px;
  height: 13.5px;
  margin-left: 10px;
}

.asset-list-card .card-text {
  font-size: 14px;
  color: rgba(33, 33, 33, 1);
  font-weight: 700;
  margin-bottom: 0.4rem;
  cursor: pointer;
}

.asset-list-card .card-text:hover {
  color: #45bcd2;
  text-decoration: underline;
}

.asset-list-card .btn.btn-outline-info {
  font-size: 10px;
}

.asset-list-card .btn.btn-secondary {
  font-size: 10px;
  opacity: 0.3;
}

.card-footer-action {
  display: block;
  font-size: 10px;
}

.card-footer-action .circle {
  padding: 3px 4px;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  text-align: center;
  background: #f6971a;
  color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.asset-list-card  .card-footer-action.partners div.circle:nth-child(2) {
  margin-left: -12px !important;
  background-color: #FDEAD1;
  color: #96611B;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.card-footer-action .square {
  padding: 7px 4px;
  width: 25px;
  height: 25px;
  text-align: center;
  background: #fdead1;
  color: #96611b;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  font-size: 7px;
}

.card-footer-action .count {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  padding: 4px 4px;
}

.action-btn.dropdown .btn {
  font-size: 1.5em;
  top: -3px;
  position: relative;
  color: #000;
  padding: 0;
}

.action-btn.dropdown .dropdown-toggle::after {
  display: none;
}

.action-btn .btn-light {
  background-color: transparent;
  border-color: transparent;
}

.action-btn .dropdown-menu {
  font-size: 10px;
}

.asset-list-card .card-footer-action div.circle {
  margin-right: 5px;
  margin-left: 0px;
}

.card-footer-action div.square {
  margin-left: -5px;
}

.asset-list-card .x-count {
  position: absolute;
  right: auto;
  background-color: #fff;
  border-radius: 20px;
  font-size: 14px;
  padding: 2px 6px;
  top: auto;
  border: 1px solid rgba(33, 33, 33, 0.12);
  margin-left: 72px;
  margin-top: 11px;
}

.asset-list-card .table-responsive {
  /* overflow: scroll; */
    /* height: 73px; */
    margin: 10px 0;
}
 ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.asset-list-card .check-box {
  margin-top: 0px;
  left: 4px;
  position: relative;
}

.vr-divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  height: 32px;
}

.asset-list-card td:last-child {
  width: 32px;
}

.asset-list-card td:nth-child(10) {
  width: 88px;
}

.asset-list-card td:nth-child(6) {
  width: 225px;
}

.asset-list-card td:nth-child(4) {
  width: 225px;
}

.asset-list-card td:nth-child(2) {
  width: 110px;
}

.asset-list-card td:nth-child(3) {
  width: 50px;
}

.asset-list-card td:nth-child(1) {
  width: 30px;
}

td.vr-border {
  width: 15px;
}

.asset-list-card td:nth-child(2) img {
  background-color: rgba(0, 0, 0, 0.125);
  border: 1px solid rgba(0, 0, 0, 0);
}

.asset-list-card.selected-card {
  background: linear-gradient(
      0deg,
      rgba(69, 188, 210, 0.08),
      rgba(69, 188, 210, 0.08)
    ),
    #ffffff;
  border-color: #45bcd2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.asset-list-card .card-title {
  white-space: nowrap;
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.asset-list-card .card-title.status {
  width: auto;
}

.asset-list-card .card-text {
  white-space: nowrap;
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.asset-list-card .asset-bookmark {
  position: relative;
  font-size: 16px;
  height: auto;
  color: #000;
  z-index: 1;
  cursor: pointer;
}

.asset-list-card .asset-bookmark.check {
  color: #45bcd2;
}

.asset-list-card .child-user.table td:nth-child(1) {
  width: 110px;
}

.asset-list-card .child-user.table td:nth-child(2) {
  width: 32px;
}

.asset-list-card .child-user.table td:nth-child(4) {
  width: 20px;
}

.asset-list-card .child-user.table td:nth-child(6) {
  width: 85px;
}

/* // microsite // */
.microsite-css .asset-list-card td:nth-child(2) {
    width: 40px;
}

.microsite-css .asset-list-card td:nth-child(4) {
    width: 40px;
}

.microsite-css .asset-list-card td:nth-child(5) {
    width: 120px;
}

.microsite-css .asset-list-card td:nth-child(3) {
    width: 140px;
}

.microsite-css .asset-list-card td:nth-child(6) {
    width: 0px;
}

.microsite-css .asset-list-card td:nth-child(10) {
    width: 20px;
}