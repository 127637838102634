.active-nav{
    border-bottom:3px solid #f6971a;
    color: #f6971a !important;
}

.email-container{
    height: 1103px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--Outline-Black, rgba(33, 33, 33, 0.12));
    background: #FFF;
}

.pick-container{
    display: flex;
    width: 1280px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(33, 33, 33, 0.12);
    background: #FFF;
}

.custom-button{
    display: flex;
    padding: 12px 15px 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border: 1px dashed #45BCD2;
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-button:hover{
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    border: 1px dashed #45BCD2;
}   

.preview-mode{
    background-color: #F8F8F8;
    /* border: 1px solid var(--Outline-Black, rgba(33, 33, 33, 0.12)); */
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.custom-font-features {
    color: rgba(33, 33, 33, 0.30);
    font-feature-settings: 'clig' off, 'liga' off;
}

.custom-style p {
    color: #212121;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}

.preview-mode .custom-style-dear {
    color: #212121;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}

.input-fix-height{
    height:44px;
    font-size: 14px;
}

.hash-email .searchWrapper{
    height:44px;
    font-size: 14px;
    display: flex;
    padding: 12px 12px;
    align-items: center;
    gap: 10px;
    border: 1px solid #dee2e6;
    align-self: stretch;
}

.primary-color-text{
    color:#45bcd2;
}

.primary-color-bg{
    background-color:#45bcd2;
}

.active-tab-sip{
    background-color: #45bcd2;
    color: white;
}

.button-custom{
    padding: 5px 10px;
}

.button-radio-container {
    border: 1px solid #dee2e6;
    display: flex;
    border-radius: 8px;
    width:fit-content
}

.button-radio-container span {
    cursor: pointer;
}

.border-left-round{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 1px solid #dee2e6;
}

.border-right-round{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #dee2e6;
}

.url-max-size-email {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    align-items: base;
    justify-content: left;
}

.radio-btn .form-check {
    display: block !important;
    align-items: center;
    gap: 10px;
}

.tox-tinymce{
    border-color: #dee2e6 !important;
}

.css-13cymwt-control{
    height: 44px;
    border-color: #dee2e6 !important;
}

.css-t3ipsp-control{
    height: 44px;
    border-color: #dee2e6 !important;
}

.css-1u9des2-indicatorSeparator{
    display: none;
}

.plus-button{
    background-color: #45bcd2;
    color: white;
    border-radius:10px;
    border-color: #FFF;
}

.btn-pos{
    margin-top: 20px !important;
}

.auto-email .search-wrapper{
    width: 100%;
    display: inline-table;
    padding-bottom: 8px;
    padding-top: 8px;
}

.auto-email .icon_cancel.closeIcon {
    display: block;
}

.card-custom{
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 11px;
    border: 1px solid rgba(33, 33, 33, 0.12);
    background: #FFF;
}

.card-custom .internal-card{
    color: #212121;
    font-family: Inter;
    font-size: 16px;
    margin-right: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-custom .internal-card img{
    margin-left: 10px;
}

.card-holder-custom{
    display: flex;
    width: 945px;
    height: 400px;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
}

.or{
    color: rgba(33, 33, 33, 0.75);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-template-heading{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;
    align-self: stretch;
    color: #212121;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-fields-card {
    padding: 0;
    border-radius: 11px;
}

.pick-fields-card nav{
    border-bottom: 1px solid #dee2e6;
}

.pick-template-row .card-img-top{
    width: 100%;
    height: auto;
    flex-shrink: 0;
}   

.pick-template-row .card-text{
    color: #212121;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-template-row .card-body{
    padding: 8px 5px;
}

.icon-position-status{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}