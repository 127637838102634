.campaign .search-field {
    width: 100%;
    /* float: left; */
    font-style: italic;
    /* z-index: 3; */
    position: relative;
    padding: 0.65rem 0.5rem;
    top: -5px;
}

.campaign .head-btn{
    gap: 15px;
    justify-content: end;
    display: flex;
}

.container-set{
    margin: 0;
    padding: 40px 50px 15px;
    background-color: #f8f8f8;
    max-width: 100%;
}

.campaign-row{
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.campaign-title{
    color: #212121;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    line-height: normal;
}

.campaign-toggle-btn{
    height: 35px;
}

.image-div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 10%;
}

.time-date-div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 7%;
}

.time-date-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight:bold;
    margin: 0;
}

.time-date-div .disable-date {
    color:gray;
    font-weight: 500;
    margin: 0;
}

.table-name{
    color: #212121;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.adjust-width {
    width: 11%;
}

.adjust-width.date {
    width: 15%;
}

.right-bar-icon {
    flex: 0 0 auto;
    /* width: 3%; */
    margin-left: 10px;
    cursor: pointer;
}

.right-bar-icon svg{
    font-size: 20px;
}

.right-bar-icon .edit-icon{
    font-size: 13px;
}

.capsule-dropdown{
    border-radius: 30px;
    width: 50%;
}

.capsule-dropdown-initial{
    border-radius: 30px;
    width: 20%;
}

.list-campaign .hashtag-list-card {
    border: 1px solid rgba(33, 33, 33, 0.12) !important;
    border-left: none;
    border-right: none;
    border-top:none;
    border-radius: 10px;
    padding: 10px 0px;
}

.list-campaign .hashtag-list-title-card {
    border: none !important;
    border-left: none;
    border-right: none;
    border-top:none;
    border-radius: 0px;
    padding: 10px 0px 0px;
}

.listing-container .list-campaign .hashtag-list-title-card {
    padding: 0 !important;
}

.list-campaign .selectedAssetActions {
    padding: 4px 16px;
    background: rgba(69, 188, 210, 0.08);
    border-radius: 5px;
    margin-bottom: 5px;
}

.list-campaign .title-weight{
    font-weight: 600 ;
}

.list-campaign .list-custom{
    border-top: 1px solid rgba(33, 33, 33, 0.12);
}

.list-campaign .card-footer-action {
    display: block;
    font-size: 14px;
}


.list-campaign .bordering-custom{
    border: none;
    padding-left: 20px;
}

.list-campaign .hashtag-name-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 24px;
    width: 20%;
    cursor: default;
}

.list-card-tags {
    font-weight: 900;
}

.list-campaign .edit-icon {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #64B6E7;
    cursor: pointer;
}

.campaign-head{
    padding:40px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.background-color-add{
    background: #f8f8f8;
}

.analytic-container{
    /* background-color: aquamarine; */
    padding: 10px;
    text-align: left;
}

.container-right{
    padding-left: 40px;
}

.container-left{
    padding-right: 55px;
    position: relative;
}

.container-left::before {
  content: '';
  position: absolute;
  top: 0;
  /* width: 10px; */
  height: 30%;
  transform: translateY(100%);
  bottom: 0;
  right: 0%; /* Adjust this value to change the position of the border */
  border-left: 1px solid #cfcece; /* Change the color and style as needed */
}

.analytic-container div p {
    margin: 0;
    font-size: 14px;
}
.progress{
    height: 1px;
    background-color: #cfcece;
}
.analytic-container div .progress-bar{
    background: green !important;
}

.inner-analy p, .sub-head{
    margin: 0;
    text-align: left;
    font-size: 16px;
    color:#8e8e8e
}

.inner-analy .main-heading-font{
    color: #000000;
    font-weight: 700;
    font-size: 18px;
}

.inner-analy .custom-diagonal-first{
    color: #64B6E7;
    font-size: 26px;
}

.new-col{
    border-bottom: 1px solid #cfcece;
}

.new-col-left-hr {
  position: relative;
}

.new-col-left-hr::before {
  content: '';
  position: absolute;
  top: 0;
  /* width: 10px; */
  height: 80%;
  transform: translateY(10%);
  bottom: 0;
  left: 0%; /* Adjust this value to change the position of the border */
  border-left: 1px solid #cfcece; /* Change the color and style as needed */
}

.line-customm-height{
    line-height: 32px;
}

.right-line-customm-height{
    line-height: 33px;
}

.main-div-theme{
    background: #fcfcfc;
    padding: 0px 10px 5px 15px;
}

.search-icon {
    margin-left: 0 !important;
    position: absolute;
    opacity: 0.5;
    z-index: 111;
    right: 28px;
    top: 5px;
}

.add-new-asset-btn-camapign {
  border: 1px solid #45bcd2;
  background-color: white;
  color: #45bcd2;
}

.active-nav{
    border-bottom:3px solid #f6971a;
    color: #f6971a !important;
}

.email-container{
    height: 1103px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--Outline-Black, rgba(33, 33, 33, 0.12));
    background: #FFF;
}

.pick-container{
    display: flex;
    width: 1280px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(33, 33, 33, 0.12);
    background: #FFF;
}

.pick-container-screen{
    display: flex;
    width: 1280px;
    padding: 0px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    background: #FFF;
}

.custom-button{
    display: flex;
    padding: 12px 15px 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border: 1px dashed #45BCD2;
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-button:hover{
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    border: 1px dashed #45BCD2;
}   

.preview-mode{
    background-color: #F8F8F8;
    /* border: 1px solid var(--Outline-Black, rgba(33, 33, 33, 0.12)); */
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.custom-font-features {
    color: rgba(33, 33, 33, 0.30);
    font-feature-settings: 'clig' off, 'liga' off;
}

.custom-style p {
    color: #212121;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}

.preview-mode .custom-style-dear {
    color: #212121;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}

.input-fix-height{
    height:44px;
    font-size: 14px;
}

.hash-email .searchWrapper{
    height:44px;
    font-size: 14px;
    display: flex;
    padding: 12px 12px;
    align-items: center;
    gap: 10px;
    border: 1px solid #dee2e6;
    align-self: stretch;
}

.primary-color-text{
    color:#45bcd2;
}

.primary-color-bg{
    background-color:#45bcd2;
}

.active-tab-sip{
    background-color: #45bcd2;
    color: white;
}

.button-custom{
    padding: 5px 10px;
}

.button-radio-container {
    border: 1px solid #dee2e6;
    display: flex;
    border-radius: 8px;
    width:fit-content
}

.button-radio-container span {
    cursor: pointer;
}

.border-left-round{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 1px solid #dee2e6;
}

.border-right-round{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #dee2e6;
}

.url-max-size-email {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    align-items: base;
    justify-content: left;
}

.radio-btn .form-check {
    display: block !important;
    align-items: center;
    gap: 10px;
}

.tox-tinymce{
    border-color: #dee2e6 !important;
}

.css-13cymwt-control{
    height: 44px;
    border-color: #dee2e6 !important;
}

.css-t3ipsp-control{
    height: 44px;
    border-color: #dee2e6 !important;
}

.css-1u9des2-indicatorSeparator{
    display: none;
}

.plus-button{
    background-color: #45bcd2;
    color: white;
    border-radius:10px;
    border-color: #FFF;
}

.btn-pos{
    margin-top: 20px !important;
}

.auto-email .search-wrapper{
    width: 100%;
    display: inline-table;
    padding-bottom: 8px;
    padding-top: 8px;
}

.auto-email .icon_cancel.closeIcon {
    display: block;
}

.card-custom{
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 11px;
    border: 1px solid rgba(33, 33, 33, 0.12);
    background: #FFF;
}

.card-custom .internal-card{
    color: #212121;
    font-family: Inter;
    font-size: 16px;
    margin-right: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-custom .internal-card img{
    margin-left: 10px;
}

.card-holder-custom{
    display: flex;
    width: 945px;
    height: 400px;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
}

.or{
    color: rgba(33, 33, 33, 0.75);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-template-heading{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;
    align-self: stretch;
    color: #212121;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-fields-card {
    padding: 0;
    border-radius: 11px;
}

.pick-fields-card nav{
    border-bottom: 1px solid #dee2e6;
}

.pick-template-row .card-img-top{
    width: 100%;
    height: auto;
    flex-shrink: 0;
}   

.pick-template-row .card-text{
    color: #212121;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pick-template-row .card-body{
    padding: 8px 5px;
}

.design_padding{
    padding: 40px;
}

.card-text-spec{
    font-size: 12px !important;
}

.campaign .card-body-wrapper {
    height: 18px;
    padding: 0px 10px;
}

.campaign .card-title {
    font-size: 14px;
    color: rgba(33, 33, 33, 0.75);
    margin-bottom: 0;
}

.campaign .solution_types {
    cursor: pointer;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
}

.campaign .pos {
    top: 0px !important;
}

.image-magic{
    height: 25px;
}

.pick-template-active{
    box-shadow: 0px 0px 20px #45bcd2;
}

.capitalize-text{
    text-transform: capitalize;
}

.camp-head{
    color: #212121;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: normal;
}

.css-1jqq78o-placeholder{
    font-weight: 400;
    font-size: 15px;
    color: #6b6f72 !important;
    margin: 0 5px !important;
}

.p-r .form-check-label{
    font-weight: 500;
}

.form-floating>label {
    font-weight: 400;
    font-size: 15px;
    padding-top: 10px;
    color: #6b6f72 !important;
    margin: 0px !important;
}

.create-list{
    font-size: 14px;
    color: #45bcd2;
    cursor: pointer;
}

.react-datepicker-wrapper{
    width: 100% !important;
}

.email-campaign .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 44px !important;
    width: 100%;
    font-size: 14px;
}

.save-proceed-btn-run {
    width: 110px;
    text-align: left;
    float: right;
}

.edit-proceed-btn-run{
    width: auto;
    text-align: left;
    background-color: #fff;
    color: #45bcd2;
    border-color: #45bcd2;
    padding: 2px 10px;
}

.save-color{
    background-color:#000;
    border-color: #000;
}

.subject-preview{
    color: #656565;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.form-floating>label{
    z-index: 0 !important;
}

.campaign .multiSelectContainer input {
    background: transparent;
    border: none;
    margin-top: 0px !important;
}

.pick-template-row .no-data.asset, .direct-list .no-data.asset, .list-campaign .no-data.asset {
    position: absolute;
    width: auto;
    left: 50%;
    margin-top: 7%;
    margin-left: 0;
}

.more-filters .dropdown .btn-light{
    margin: 0 !important;
}

.tagline {
    font-size: 22px;
    color: #585858;
}

.selected-card {
    background: #ffffff !important;
    border-color: #45bcd2 !important;
    box-shadow: 0px 0px 10px #45bcd2 !important;
}

.movable-add-button{
    background: #45bcd2;
    border: none;
}

.movable-add-button:hover{
    background: #45bcd2;
    border: none;
}

.movable-add-button:first-child:active, :not(.btn-check)+.movable-add-button:active {
    background: #45bcd2;
    border: none;
}

.campaign-row .dropdown-menu {
    font-size: 13px;
    z-index: 9 !important;
}

.list-popup{
    height: 70vh;
    overflow-y: auto;
}

.listing-container .modal-scroll{
    font-weight: 900;
}

.listing-container .btn-close{
    background-color: gray;
    color: #fff !important;
    padding: 12px;
}

.listing-container .header-color{
    background-color: rgb(237, 237, 237)
}

.listing::-webkit-scrollbar-track {
  background: #f8f8f8;
  -webkit-box-shadow: 0px 0px 0px #f8f8f8;
  border: none;
}
.listing::-webkit-scrollbar-thumb {
  width:1px !important;
  background: #59B3C6;
}
.listing::-webkit-scrollbar-thumb {
  border-radius: 10px;
  scrollbar-width: thin;
}
.listing::-webkit-scrollbar {
  width: 4px;
}

.listing-container .modal-title > span:first-child{
    font-weight: 600;
    font-size: 22px;
    color: #707070;
}

.view-header > span:first-child{
    font-weight: 600;
    font-size: 22px;
    color: #707070;
}

.search-field-list {
    width: 364px;
    font-style: italic;
    position: relative;
    padding: 0.3rem 0.5rem;
}

.search-icon-list {
    position: absolute;
    opacity: 0.5;
    z-index: 9;
    right: 97px;
    top: 0;
}