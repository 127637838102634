.sub-breadcrumb {
  font-size: 14px;
  white-space: nowrap;
}

.sub-breadcrumb ul {
  display: flex;
  justify-content: center;
  padding: 0;
}

.sub-breadcrumb ul li {
  list-style: none;
  padding: 0 5px;
}

.sub-breadcrumb ul li.link {
  cursor: default;
}

.sub-breadcrumb ul li.active {
  color: #f6971a;
}
.public-access {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
}
.select-box-w100 {
  width: 100%;
}
.select-type-card .card {
  background: #f8f8f8;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 10px;
  cursor: pointer;
}

.select-type-card .card:hover {
  background: linear-gradient(0deg,
      rgba(69, 188, 210, 0.08),
      rgba(69, 188, 210, 0.08)),
    #ffffff;
  border-color: #45bcd2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.select-type-card .card-title {
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}

.select-type-card .card-text {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-type-card .card-title span {
  position: absolute;
  line-height: 1;
  right: 10px;
}

.select-type-card .img-wrapper {
  height: 175px;
  text-align: center;
  width: 100%;
}
.select-type-card .card-img-top {
  border-radius: 0;
  background-color: transparent;
  border: 0;
  max-width: 215px;
  object-fit: cover;
  padding: 15px 10px;
}

.new-asset .asset-type .col-1 {
  text-align: center;
}

.new-asset .asset-type .col-1 span {
  margin: 275% 0;
  display: block;
}

.new-asset .asset-type h6 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.75);
  margin: 20px 0;
}

.new-asset .asset-type .change-type {
  font-weight: 400;
  font-size: 14px;
  /* color: rgba(33, 33, 33, 0.3); */
  color: #000;
  cursor: pointer;
}

.new-asset .asset-type .change-type:hover {
  color: #45bcd2;
}

.new-asset .asset-type .card {
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 11px;
  height: 100%;
}

.bulk-upload {
  text-align: center;
  /* padding: 20% 0; */
  padding: 150px 0;
}

.modal-body .bulk-upload {
  padding: 50px 0;
}

.asset-type .search-field {
  width: 100%;
}

.asset-type .search-icon {
  margin-left: 161px;
  margin-top: 3px;
}

.video-gallery .search-icon {
  margin-left: 255px;
}

.next-btn .btn {
  float: right;
  width: 142px;
  text-align: left;
  margin-top: 32px;
}

.next-btn .btn span {
  display: block;
  float: right;
}

.files-upload-wrapper>div>div img,
.files-upload-wrapper>div>div>div {
  width: 124px !important;
  height: 120px !important;
  font-size: 10px !important;
  box-shadow: none !important;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 8%) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-size: 100%;
  background-position: 0px -25px;
  background-color: rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem;
  padding: 5% 0;
}

.global-fields-card .files-upload-wrapper>div>div img {
  padding: 1% 0;
}

.bulk-upload.card .files-upload-wrapper.pdf>div>div>div {
  background-image: url("../../../../assets/pdf-img.png");
  padding: 0% 0;
}

.bulk-upload.card .files-upload-wrapper.video>div>div>div {
  background-image: url("../../../../assets/video-img.png");
  padding: 0% 0;
}

.files-upload-wrapper>div>div>div>div {
  margin: 0 !important;
  /* padding: 15% 0; */
  padding-top: 55px;
}

.files-upload-wrapper button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #45bcd2;
  border: 1px solid #45bcd2;
  padding: 0.375rem 0.75rem;
  margin-top: 20px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.global-fields-card {
  padding: 3rem;
  padding-bottom: 1rem;
  border-radius: 11px;
}

.global-fields-card .checkbox-btn {
  display: inline-block;
}

.global-fields-card .radio-btn {
  display: inline-block;
  margin-right: 17px;
}

.global-fields-card .form-control,
.global-fields-card .form-select {
  font-size: 14px;
}

.save-proceed-btn {
  width: 175px;
  text-align: left;
  font-size: 14px;
  float: right;

}

.save-proceed-btn span,
.publish-now-btn span {
  float: right;
}

.user-drop-select > div > div {
  position: absolute;
  top: 60%;
  width: 100%;
}

.user-drop > div {
  width: 100%;
}

.publish-now-btn {
  width: 225px;
  text-align: left;
  position: relative;
  float: right;
  top: 52px;
}

.publish-now-btn i {
  margin-right: 10px;
}

ul.selected-asset-list {
  /* padding-left: 0; */
  margin-bottom: 1.5rem;
  display: flex;
    align-items: center;
    gap: 0px;
    padding: 0 0 0 13px
}
.radio-btn .form-check{
  display: inline-flex;
    align-items: center;
    gap: 10px;
}
ul.selected-asset-list li {
  list-style: none;
  padding: 8px;
  width: 45px;
  height: 45px;
  display: inline-block;
  text-align: center;
  color: #f6971a;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
}

ul.selected-asset-list li.asset {
  padding: 0px;
}

ul.selected-asset-list li.asset img {
  width: 100%;
  height: 100%;
  font-size: 6px;
  overflow: hidden;
  max-width: 45px;
  max-height: 45px;
}

ul.selected-asset-list li.asset-type-img {
  padding: 0;
  border: 0;
}

ul.selected-asset-list li.asset-type-img img {
  width: 45px;
  height: 45px;
}

ul.selected-asset-list li {
  border: 1px solid #f6971a;
}



ul.selected-asset-list li.active {
  border: 4px solid #f6971a;
}

ul.selected-asset-list li.web-icon.active {
  border: 1px solid #f6971a;
}

ul.selected-asset-list li.web-icon {
  font-weight: bold;
  border-radius: 50px;
  line-height: 23px;
}

ul.selected-asset-list li.active {
  background-color: #f6971a;
  color: #fff;
}

ul.selected-asset-list li.active.asset.file {
  background-color: transparent;
}

ul.selected-asset-list li.add-more {
  background: #ebf5f7;
  border: 2px dashed #45bcd2;
  color: #45bcd2;
  font-weight: bold;
  position: relative;
  top: 3px;
}

.add-more-video {
  background: #ebf5f7;
  border: 2px dashed #45bcd2;
  color: #45bcd2;
  position: relative;
  top: 3px;
  border-radius: 0;
  width: 100%;
  font-weight: 500;
}

.add-more-video span {
  font-size: 21px;
  line-height: 0;
}

ul.selected-asset-list .asset.file {
  border: 0;
  margin-right: 15px;
  text-align: center;
  width: 55px;
  top: 0 ;
  position: relative;
}

ul.selected-asset-list .asset.file .file-type-icon {
  font-size: 32px;
  line-height: 0;
  color: red;
}

ul.selected-asset-list .asset.file .file-type-name {
  font-size: 10px;
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: black;
}

ul.selected-asset-list .asset.file .active-icon {
  color: #f6971a;
  font-size: 16px;
  position: absolute;
  bottom: -18px;
  left: 18px;
}

.pdf-preview {
  text-align: center;
  padding: 150px 0;
}

.pdf-preview .file-type-icon {
  display: block;
  font-size: 75px;
  color: red;
}

.global-fields-card.base {
  border: 0;
}

.global-fields-card.base {
  padding: 0;
}

.global-fields-card.base>.row>.col {
  padding: 2rem;
  padding-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.global-fields-card.base>.row>.col:first-child {
  border-radius: 11px 0px 0px 11px;
}

.global-fields-card.base>.row>.col:nth-child(2) {
  background-color: #f8f8f8;
  border-radius: 0px 11px 11px 0px;
}

.post-img {
  margin: 0 auto;
  text-align: center;
}

.post-img img {
  height: 374px;
  max-width: 374px;
  width: 100%;
  margin-top: 30px;
}

.base-action-btn {
  display: block;
  max-width: 374px;
  padding: 0;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: -26px;
}

.base-action-btn li {
  margin: 0 5px;
  display: inline-block;
}

.base-action-btn li.icon {
  list-style: none;
  width: 48px;
  height: 48px;
  border: 1.625px solid rgba(33, 33, 33, 0.12);
  border-radius: 82.875px;
  color: #45bcd2;
  background-color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 4px;
  cursor: pointer;
}

.base-action-btn li.btn {
  padding: 10px;
  width: 140px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.3);
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: rgba(33, 33, 33, 0.3);
  margin-top: 5px;
  margin-left: 35px;
}

div.thumbImg {
  width: 124px !important;
  height: 120px !important;
  font-size: 10px !important;
  box-shadow: none !important;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 8%) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem;
  padding: 5% 0;
}

.global-fields-card div.thumbImg {
  padding: 1% 0;
}

div.thumbImg img {
  width: 100%;
  height: 100%;
}

/* .react-calendar-datetime-picker .input-picker--input { 
  display: block;
  width: 100%;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  text-align: left !important;
  height: auto !important;
  padding: 4px 13px !important;
} */

.react-datepicker-wrapper .react-datepicker__input-container input {
  border: none !important;
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  line-height: 16px;
  height: 35px !important;
  padding: 4px 13px !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input:focus{
  outline: 0 !important;
}


/* .react-calendar-datetime-picker .header {
  background-color: #45bcd2 !important;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #45bcd2 !important;
  color: white;
}

.react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0px 0px 0px 2px #328695 !important;
} */


.device-img {
  position: relative;
  text-align: center;
}
.device-img img {
  height: 432px;
}

.schedule-icon {
  position: relative;
  float: right;
  margin-top: -30px;
  margin-right: 14px;
}

.smartFolder-multiSelect>div>div.multiSelectContainer {
  margin-top: 0px !important;
  /* margin-bottom: 20px; */
  margin-bottom: 0px;
}

.edit-keywords {
  display: block;
  float: right;
  margin-right: 14px;
  margin-top: -29px;
  color: #000;
  cursor: pointer;
}

.edit-keywords:hover {
  color: #f6971a;
}

.keyword-options .add,
.keyword-options .remove {
  height: 40px;
}

.new-asset .asset-type .col-1 .add span,
.new-asset .asset-type .col-1 .remove span {
  margin: 0;
  display: block;
}

.multi-select-keyword {
  height: 96px;
}

.add-keyword-btn span {
  font-size: 20px;
  line-height: 0px;
  top: -1px;
  position: relative;
  right: -6px;
}

.remove-url-field {
  float: right;
  margin-top: -55px;
  margin-right: -5px;
  cursor: pointer;
}

ul.selected-asset-list .form-check-input[type=checkbox] {
  position: absolute;
  margin-left: 8px;
  margin-top: -4px;
  cursor: pointer;
}

.publish-mobile-layout {
  width: 167px;
  position: absolute;
  height: 350px;
  text-align: left;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.publish-mobile-layout .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.publish-mobile-layout .desc {
  font-size: 10px;
  margin-bottom: 8px;
}

.publish-mobile-layout .img img {
  width: 100%;
  height: 200px;
  border-radius: 7px;
  object-fit: contain;
}

.react-calendar-datetime-picker .input-picker .input-picker--clearBtn {
  top: 43% !important;
  right: 0.5125rem !important;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #999;
  background-color: #eee;
  border-color: #eee;
}

.global-fields-card .form-control,
.global-fields-card .smartFolder-multiSelect>div {
  width: 100%;
  float: right;
}

.global-fields-card .caption {
  height: 165px;
}

.global-fields-card .greeting {
  float: right;
  /* width: 79%; */
}

.new-asset.publish .global-fields-card .form-control,
.new-asset.publish .global-fields-card .smartFolder-multiSelect>div {
  width: 100%;
  float: none;
}

.mandatory {
  font-size: 18px;
  line-height: 0;
  color: red;
  top: 2px;
  position: relative;
}

.publish .mandatory {
  float: right;
  margin-top: -18px;
  margin-right: -12px;
}

.publish .smartFolder-multiSelect>div>div.multiSelectContainer {
  margin-bottom: 20px;
}

.smartFolder-multiSelect {
  display: inline-block;
  width: 100%;
}

.global-fields-card .ms-cus-url .form-control {
  width: 100%;
}

.global-fields-card .ms-cus-url {
  display: inline-block;
  width: 100%;
}

.selected-asset-list>li:first-child,
.selected-asset-list>li:nth-child(2) {
  cursor: default;
}

.bulk-upload .files-upload-wrapper>div>div {
  width: auto !important;
  display: inline-flex !important;
}

.bulk-upload .files-upload-wrapper .customize-card {
    width: 15% !important;
    display:block !important;
}

.post-img .file-type-name {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 20px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 80px;
  width: 100%;
}

.bulk-upload .files-upload-wrapper button {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.chip {
  background: #45bcd2 !important;
}

.highlightOption,
.multiSelectContainer li:hover {
  background: #45bcd2 !important;
}

.react-calendar-datetime-picker .time {
  /* background-color: #0dcaf0;
  color: #fff; */
  background: #45bcd2 !important;
  border-radius: 50px;
  padding: 5px 0;
}

.react-calendar-datetime-picker .time--title {
  margin-right: 0.5rem;
  font-size: 1.125rem;
  color: #fff !important;
  text-transform: capitalize;
}

.react-calendar-datetime-picker .field-wrapper .time-fieldset .time--input {
  background-color: transparent;
  font-weight: 500;
  color: #fff !important;
}

.react-calendar-datetime-picker .time .field-wrapper .time-fieldset button {
  background-color: transparent;
  opacity: 1;
}

.react-calendar-datetime-picker .time .time-fieldset.is-disabled {
  opacity: 1 !important;
}

.react-calendar-datetime-picker .time .field-wrapper {
  color: #fff;
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec svg {
  transform: translate(-50%, -50%) scale(0.95);
}

/* .ReactCrop{
  max-width: 35% !important;
} */
.close-icon-asset{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    color: white;
    background: #a3a3a3;
    border-radius: 5px;
}

.thumb-div{
  width:100%;
  height:100%;
  position: relative;
}

.cropImg {
  position: absolute;
  margin-top: 22px;
  margin-left: 412px;
  border: 3px solid red;
  top: 0;
}

.cropper-recommend{
    position: absolute;
    font-size: 10px;
    top: -18%;
    left: 40%;
}

.brochureThumbInfo {
  position: absolute;
  top: -38px;
  left: 185px;
  max-width: 300px;
}

.date-picker div {
padding: 0;
margin-bottom: 8px;
}
.ReactCrop {
  position: relative !important;
  display: inline-block !important;
  cursor: crosshair !important;
  overflow: scroll !important;
  max-width: 100% !important;
  max-height: 350px !important;
}
.device-img .mobile-img{
  position:relative
}
.publish-setup-wrap{
  padding: 12px 16px;
}
.publish-setup-wrap hr{
  border-top: 1px solid #ccc;
}
.publish-media {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width:992px){
  .sub-breadcrumb ul {
    flex-wrap: wrap;
    padding: 20px 0px;
}
.sub-breadcrumb{
  padding: 0 20px;
}

.brochureThumbInfo {
 margin: 0;
  left: 0;
  top: -113px;
}
.custom-row{
  margin: 0;
}
.selectassest-title {
  font-size: clamp(16px,5vw,18px);
  margin-bottom: 22px;
}
.select-type-card .img-wrapper {
  height: 190px;
}
.new-asset .asset-type h6{
  margin-bottom: 6px;
}
.post-img img {
  height: 235px;
  max-width: 300px;
  padding: 20px;
  object-fit: cover;
  padding-bottom: 48px;
  padding-top: 0;
}
.b-need{
  margin-left: 35px;
  float: unset !important;
  width: 79%;
}
img.vedio-img {
  margin-top: 64px;
}
.date-picker.row {
  margin: 0;
  flex-direction: column;
  
}

}
@media (max-width:526px) {
  .publish-media {
    display: initial;
  }
  .global-fields-card .chip{
    font-size: 10px;
  }
}


/* email */

.active-tab{
  border: 1px solid #45bcd2 !important;
}

.email-fields-card {
    padding: 3rem;
    padding-bottom: 1rem;
    border-radius: 11px;
}