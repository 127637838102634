.login.container .radio-btn {
  float: left;
  margin-right: 50px;
}

.login.container .title {
  margin-bottom: 50px;
}

.login.container > div.row > div {
  /* padding: 10% 6%; */
}

.login.container > div.row > div:first-child {
  text-align: center;
  /* padding: 20% 0; */
}

.login.container > div.row > div:first-child img {
  width: 50%;
}

.login.container {
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 10px;
  padding: 2rem;
  background: #f8f8f8;
}

.login.container > div.row > div:last-child {
  /* background: #f8f8f8;
  border-radius: 0 10px 10px 0; */
}

.login-company-logo {
  max-width: 224px;
  max-height: 50px;
}
.login-dual-ring{
    display: inline-block;
    width: 69px;
    height: 15px;
    padding: 0px 25px;
    position: initial;
}
.login-dual-ring:after {
  content: " ";
  display: block;
  width: 21px;
  height: 20px;
  margin: 0px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.privacy-policy{
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 12px;
}

.privacy-policy a:hover {
  color: #45bcd2 !important;
}

.eye-password{
  position: absolute;
  top: 50%;
  right: 10px;
  color: rgb(164, 164, 164);
  transform: translateY(-50%);
}

.eye-password-active{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #45bcd2;
}
