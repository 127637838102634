
@media (min-width: 768px) {
  /* Styles for md (medium devices) and above */
  .quick-filters {
    position: relative;
    min-height: 100vh !important;
}
}
@media (max-width: 768px) {
  /* Styles for md (medium devices) and above */
  .quick-filters {
   flex-wrap: nowrap;
   overflow-x: auto;
}
.quick-filters::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.quick-filters .active.nav-link {
  border: 2px solid #ccc !important;
  border-radius: 5px;
  color: #000;
}
}

.quick-filters {
text-transform: capitalize;
}

.quick-filters h5 {
  font-size: 12px;
  font-weight: 700;
  color: rgba(33, 33, 33, 0.75);
}

.quick-filters .active.nav-link {
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
}


.nav-link {
  color: rgba(33, 33, 33, 0.5);
  padding: 0.5rem 0.5rem !important;
}

.nav-link:hover {
  color: rgba(33, 33, 33, 1);
}



.active.nav-link span {
  font-size: 20px;
  color: #f6971a;
  line-height: 0.7;
  float: right;
}

.folderName {
  font-size: 14px;
  font-weight: bold;
}

.folder-sub-head {
  font-size: 14px;
}

.shine {
  background: #f6f7f8;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  /* background-repeat: no-repeat; */
  background-size: 800px 104px !important;
  /* display: inline-block; */
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
.folder-details-list div.h5 {
  font-size: 18px;
  font-weight: 400;
}

.folder-details-list .folder-types {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33,33,33,.5);
  line-height: 1.3rem;
  margin-top: 5px;
}

.folder-details-list .h6 {
  font-size: 14px;
}

.folder-details-list {
  border-top: 1px solid #ccc;
  padding-top: 1.6rem;
}
