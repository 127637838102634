.input-form-column,.flex-col  {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: flex-end;
  flex-direction: column;
}
.input-form-column a, .flex-col a{
  font-size: 12px;
}
/* .global-fields-card .smartFolder-multiSelect>div {
  width: 68% !important;
} */
.custom-flex{
  display: flex;
  
}

.file-add{
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
}

.icon-select-container {
  position: relative;
  width: 200px;
}

.icon-select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon-select-option {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.delete-icon {
  margin-right: 8px;
  cursor: pointer;
}

.url-max-size {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.personalize-video .searchWrapper.singleSelect{
  padding: 5px !important;
}

.input-form-column >div{
  width: 100%;
}
/* 
.global-fields-card .form-control, .global-fields-card .form-select, .global-fields-card .search-wrapper, .global-fields-card .url-max-size{
  max-width: 178px;
}comment this one */

/* only for assets-detail */
@media (max-width:992px) {
  .body-container {
    padding: 0 !important;
  }

  .custom-row {
    margin: 0;
  }
  .global-fields-card .smartFolder-multiSelect>div{
    max-width: 200px;
  }
  .selectassest-title {
    font-size: clamp(16px, 5vw, 18px);
    margin-bottom: 22px;
  }

  .global-fields-card {
    padding: 2rem 1.15rem;
  }

  .smartFolder-multiSelect {
    display: flex;
    justify-content: space-between;
    gap: 3px !important;
  }

  .global-fields-card label,
  ::placeholder {
    font-size: clamp(10px, 4vw, 12px);
  }

  .global-fields-card label {
    min-width: 95px;
  }

  .global-fields-card input,
  .global-fields-card textarea,
  .global-fields-card select {
    max-width: 200px;
    width: 100%;
  }

  .global-fields-card .form-check input {
    width: 1rem !important;
  }

  /* .custom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .global-fields-card .checkbox-btn {

    margin-left: 0 !important;
    width: 100%;
    margin-bottom: 0.5rem !important;
  }
}