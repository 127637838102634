
.save-proceed-btn {
    position: initial !important;
}


@media (max-width:992px) {
    .asset-type {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
        padding: 0 20px;
        gap: 20px;
    }

    .post-img {
        padding-bottom: 36px;
    }

    .post-img img {
        height: clamp(200px, 5vw, 374px);
        max-width: clamp(200px, 5vw, 374px);
    }
    .smartFolder-multiSelect {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 7px;
        /* flex-wrap: wrap; */
    }
    .smartFolder-multiSelect label, .caption label {
        font-size: clamp(10px, 3vw, 12px);
    }
    .new-asset .custom-row {
        margin: 0 !important;
    }
    .global-fields-card.base>.row>.col {
        padding: 2rem 1rem;
    }
    input::placeholder,textarea {
        font-size: clamp(11px, 4vw, 12px);
    }
    .custom-btn{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2rem;
    }
    .global-fields-card .asset-type form label {
        min-width:85px !important;
    }
    .global-fields-card .asset-type form textarea , .global-fields-card .asset-type form select,  .global-fields-card .asset-type form .multiselect-container,  .global-fields-card .asset-type form input {
       
        width: 100%;
        margin-left: auto;
    }
   
    .global-fields-card .form-check {
      
        padding-left: 0;
    }
}


.update-content .files-upload-wrapper>div>div {
    justify-content: center;
}

.update-content .files-upload-wrapper.pdf>div>div>div {
  background-image: url("../../../../assets/pdf-img.png");
  padding: 0% 0;
}

.update-content .files-upload-wrapper.video>div>div>div {
  background-image: url("../../../../assets/video-img.png");
  padding: 0% 0;
}

.button-update{
    width: 30% !important;
    gap: 10px;
    display: flex;
    justify-content: center;
}

