.asset-card.card {
  /* width: 223px; */
  /* height: 293px; */
  width: 100%;
  border-radius: 10px;
  border: 0;
}

.card-img-top {
  border-radius: 10px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.125);
  border: 1px solid rgba(0, 0, 0, 0);
}

.card-tags {
  display: flex;
  position: absolute;
  margin-top: -36px;
  margin-left: -8px;
}

.download-icon-asset {
    font-size: 20px;
    color: rgba(33, 33, 33, 0.5);
    cursor: pointer;
}

.font-change{
  font-size: 10px;
  margin-left: 0px !important;
}

.list-d-icon{
    position: absolute;
    top: 35%;
    right: 0;
}

.download-icon-asset:hover {
    font-size: 20px;
    color: rgba(33, 33, 33, 0.7)
}

.card-tags div {
  background-color: #f8f8f8;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  padding: 2px 8px;
  text-transform: uppercase;
}

.new-card-tag {
  color: #d6b922;
  border-left: 1px solid #d6b922;
  border-top: 1px solid #d6b922;
  border-right: 1px solid #d6b922;
  background-color: rgba(33, 33, 33, 0.75) !important;
}

.asset-card .card-title {
  font-size: 10px;
  color: rgba(33, 33, 33, 0.75);
  margin-bottom: 0;
}

.asset-card .card-title span {
  color: rgba(33, 33, 33, 0.5);
  margin-left: 5px;
}

.asset-card .asset-bookmark {
  position: absolute;
  right: 16px;
  font-size: 16px;
  height: auto;
  color: #000;
  z-index: 1;
  cursor: pointer;
}

.asset-card .asset-bookmark.check {
  color: #45bcd2;
}

.asset-card .card-text {
  font-size: 16px;
  color: rgba(33, 33, 33, 1);
  font-weight: 700;
  margin-bottom: 0.4rem;
  height: 48px;
  width: 92%;
}

.asset-card .card-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.asset-card .card-text:hover {
  color: #45bcd2;
  text-decoration: underline;
}

.modal-body .asset-card .card-text:hover {
  color: rgba(33, 33, 33, 1);
  text-decoration: none;
  cursor: default;
}

.asset-card .hr-divider {
  margin: 0 0 0 0;
  display: block;
}

.asset-card .hr-divider hr {
  margin-bottom: 12px;
  margin-top: 26px;
}

.asset-card .btn.btn-outline-info {
  font-size: 10px;
}

.asset-card .btn.btn-secondary {
  font-size: 10px;
  opacity: 0.3;
}

.asset-card .card-body {
  margin-top: -26px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 10px 10px;
  display: inline-block;
  width: 100%;
}

.card-footer-action {
  display: flex;
  font-size: 10px;
}

.card-footer-action .circle {
  padding: 3px 4px;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  text-align: center;
  background: #f6971a;
  color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  cursor: pointer;
}

.card-footer-action .square {
  padding: 7px 4px;
  width: 25px;
  height: 25px;
  text-align: center;
  background: #fdead1;
  color: #96611b;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  font-size: 7px;
}

.card-footer-action .count {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  padding: 4px 4px;
}

.action-btn.dropdown .btn {
  font-size: 1.5em;
  top: -3px;
  position: relative;
  color: #000;
  padding: 0;
}

.action-btn.dropdown .dropdown-toggle::after {
  display: none;
}

.action-btn .btn-light {
  background-color: transparent;
  border-color: transparent;
}

.action-btn .dropdown-menu {
  font-size: 10px;
}

.action-btn.dropdown {
  position: relative;
  float: right;
}

.card-footer-action div.circle {
  margin-left: 10px;
}

.asset-card .card-footer-action div.circle:nth-child(3) {
  margin-left: -7px;
  background-color: #fdead1;
  color: #96611b;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.card-footer-action div.square {
  margin-left: -5px;
}

.asset-card .check-box {
  position: absolute;
  left: 15px;
  top: 10px;
}

.x-count {
  position: absolute;
  right: -4px;
  background-color: #fff;
  border-radius: 20px;
  font-size: 14px;
  padding: 2px 6px;
  top: -4px;
  border: 1px solid rgba(33, 33, 33, 0.12);
}

.selected-card.card .card-body {
  background: linear-gradient(
      0deg,
      rgba(69, 188, 210, 0.08),
      rgba(69, 188, 210, 0.08)
    ),
    #ffffff;
  border-color: #45bcd2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.card-body-wrapper {
  height: 78px;
}

.card-footer-action div,
.card-footer-action button {
  float: left;
}

div.share-btn {
  float: right;
  color: #F6971A;
  font-size: 14px;
  cursor: pointer;
}

.solution_types, .count { 
  cursor: pointer;
}

.solution_types {
  cursor: pointer;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
}