.main-setup-container {
    padding: 0px 15px 100px;
}

.main-setup-container h3 {
    padding: 0px 0px 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e2e2e2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
}

.left-menu {
    font-size: 15px;
}

.left-menu ul {
    list-style-type: none;
    padding: 0px;
}

.left-menu ul li {
    cursor: pointer;
    padding: 0px;
    color: #a1a1a1;
    margin: 10px 0px;
    font-weight: 500;
}

.left-menu ul li span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.left-menu ul li.active span {
    color: #45BCD2;
    border-left: 2px solid #45BCD2;
    padding-left: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

/* //////////////////////////Category//////////////////////////// */

.search-inside {
    position: relative;
    display: inline-flex;
    width: 50%;
    flex: none;
    min-width: 0;
}

.search-inside-input {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    width: 320px;
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    border-radius: 5px;
    padding: 8px 12px;
}

.search-inside-input::placeholder {
    color: #A8A8A8;
}

.input-group-text {
    background: #FFFFFF;
    border-left: none;
}

.search-inside-input:focus-visible {
    outline: none;
    border: 1px solid #CFCFCF;
}

.common-setup-button {
    background-color: #45BCD2;
    border: none;
    padding: 5px 15px;
    line-height: 31px;
}

.common-setup-button:hover {
    background-color: #45BCD2;
    border: none;
}

.right-buttons {
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
}

.right-buttons .plus-size {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin: 0px 2px;
    line-height: 0px;
    color: #FFFFFF;
}

.button-image div:first-of-type {
    display: flex;
    margin-right: 10px;
    width: auto !important;
}

.button-image button {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 1px;
    font-size: 14px;
    border: 2px dashed #45BCD2;
    cursor: pointer;
    padding: 47px 0;
    background-color: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    font-weight: 500;
}

.button-image img {
    width: 100px !important;
    height: 100px !important;
    margin: 0 !important;
    box-shadow: #ceeaef 0px 10px 30px !important;
}

.button-image .main-img {
    width: 100px !important;
    height: 100px !important;
    margin-top: 14px !important;
    margin-right: 10px !important;
    box-shadow: #ceeaef 0px 10px 30px !important;
}

.right-buttons span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(33, 33, 33, 0.75);
    margin: auto 10px;
}

.right-buttons .sort-text {
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #45BCD2;
    cursor: pointer;
}

.right-buttons .sort-text img {
    width: 13px;
    margin: 0px 3px;
}

.right-buttons .sort-text .rotate {
    transform: rotate(180deg);
}

.rotate-left {
    transform: rotate(90deg);
    width: 15px;
    margin-right: 8px;
}

.text-aligning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: left !important;
    flex: 0 0 auto;
    width: 28%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.drag-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 5%;
}

.border-bottom {
    border-bottom: 1px solid rgba(33, 33, 33, 0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-row-count {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
    cursor: pointer;
}

.column-styling {
    flex: 0 0 auto;
    width: auto;
    margin-right:5px;
    cursor: pointer;
}

.column-styling img {
    width: 12px;
}

.edit-icon {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #64B6E7;
    cursor: pointer;
}

.edit-icon svg {
    margin-right: 5px;
}

.adjust-width{
    width: 60% !important;
}

.bordering-date {
    border-left: 1px solid rgba(33, 33, 33, 0.12);
    color: rgba(33, 33, 33, 0.75);
    padding-left: 20px;
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px; */
}

.table-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 20%;
    color: rgba(33, 33, 33, 0.75);
    /* line-height: 24px; */
}

.sub-table {
    width: 80%;
    padding-top: 0;
    margin: auto;
    line-height: 24px;
}

.sub-table-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: rgba(33, 33, 33, 0.3);
    margin: auto;
    text-align: left;
}

.sub-table .table-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
    width: 20%;
}

.sub-table .edit-icon {
    flex: 0 0 auto;
    padding: 0px 20px;
    width: 14%;
    font-size: 12px;
    color: #64B6E7;
    /* margin-right: 10px; */
    cursor: pointer;
    line-height: 28px;
}

.sub-table .column-styling {
    flex: 0 0 auto;
    width: 15%;
    padding: 0;
}

/* @media screen and (max-width: 1200px) {
    .sub-table .column-styling {
        width: 15%;
    }
} */

.sub-table .text-aligning {
    flex: 0 0 auto;
    width: 24%;
}

/* /////////////////////////Add category////////////////////////////////////// */
.shimmerEffect {
    background: #f6f7f8;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    /* background-repeat: no-repeat; */
    background-size: 800px 104px !important;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.single-screen {
    width: 35%;
    margin: auto;
    margin-bottom: 100px;
}

.single-screen.single-width-adjust{
    width: 50%;
}

.single-screen .multiSelectContainer input::placeholder{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.3);
}

.single-screen .multiSelectContainer .closeIcon {
    display: none;
}

.single-screen .multiSelectContainer .chip {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0px;
    color: rgba(33, 33, 33, 0.75);
    background: #ffffff !important;
}

.single-screen span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: rgba(33, 33, 33, 0.75);
    cursor: pointer;
}

.single-screen h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    color: #212121;
    margin: 30px 0px;
    font-size: 45px;
}

.single-screen .add-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 6px;
}

.single-screen .drop-down .search-wrapper {
    width: 100%;
    background: white;
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 6px;
    color: rgba(33, 33, 33, 0.3);
    text-align: left;
    padding: 12px 16px;
}

.single-screen .drop-down .search-wrapper input {
    width: 100%
}

/* ///////////////////////// Tags //////////////////////////////// */
.tags-style {
    padding: 10px 0px;
    border-top: 1px solid rgba(33, 33, 33, 0.12);
    ;
    border-bottom: 1px solid rgba(33, 33, 33, 0.12);
    ;
}

.tags-style span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: rgba(33, 33, 33, 0.75);
}

.tags-style .ReactTags__tagInput {
    margin: 5px 0px;
}

.tags-style .ReactTags__tagInput .ReactTags__tagInputField:focus-visible {
    outline: none;
}

.tags-style .ReactTags__tagInput .ReactTags__tagInputField {
    width: 100%;
    padding: 12px 16px;
    background: #FFFFFF;

    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 6px;
}

.ReactTags__selected {
    padding: 5px 0px;
}

.ReactTags__remove {
    border: none;
    background: transparent;
    font-size: 18px;
    font-weight: 600;
    color: gray;
}

.ReactTags__selected .ReactTags__tag {
    padding: 10px;
    background: rgba(25, 148, 89, 0.2);
    border-radius: 5px;
    margin-right: 5px;
}

.save-button {
    width: 100%;
    background: #45BCD2;
    border-radius: 5px;
    border: none;
    padding: 12px 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.save-button:hover {
    background: #45BCD2;
}

.add-more-button{
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #45BCD2;
    padding: 12px 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #45BCD2;
}
.add-more-button:hover{
    border: 1px solid #45BCD2;
    background: #fff;
    color: #45BCD2;
}

.ReactTags__suggestions{
    font-size: 10px;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    padding: 2px;
    display: flex;
}

.ReactTags__suggestions ul li {
    padding: 2px;
    background: rgb(255, 255, 118);
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
}


/* hashtags */
.selected-card {
    background: linear-gradient(0deg, rgba(69, 188, 210, 0.08), rgba(69, 188, 210, 0.08)), #ffffff;
    border-color: #45bcd2;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 8%);
}

.hashtag-list-card {
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 5px;
    padding: 10px 0px;
}

.hashtag-list-card .form-check-input[type=checkbox] {
    border-radius: 0.25em;
    border-color: black;
}

.hashtag-list-card.selected-card {
    background: rgba(69, 188, 210, 0.2);
    border: 1px solid #64B6E7;
    border-radius: 5px;
    padding: 10px 0px;
}

.hashtag-list-card .table-responsive {
    overflow: revert;
}

.hashtag-list-card .table,
.hashtag-list-card td {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    vertical-align: middle;
}

.hashtag-list-card .check-box {
    margin-top: 0px;
    left: 4px;
    position: relative;
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.hashtag-list-card .hashtag-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
    width: 20%;
    cursor: default;
}

.hashtag-list-card .bordering-date.date-style {
    width: 10%;
    padding-left: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

.hashtag-list-card .bordering-date.time-style {
    width: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

.hashtag-list-card .dot {
    width: 4%;
    font-size: 5px;
}

.modal-heading-custom {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #212121;
}

.delete-popup {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}

.delete-popup .btn-primary {
    font-size: 15px;
    padding: 8px 20px;
    line-height: 20px;
    border-radius: 5px;
    background-color: #103880;
    border: none;
}

.delete-popup .btn-secondary {
    font-size: 15px;
    padding: 8px 20px;
    line-height: 20px;
    border-radius: 5px;
    background-color: rgb(178 178 178 / 30%);
    color: black;
    border: none;
}

.switch-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.switch-button div {
    margin-left: 10px;
}

.switch-button span {
    line-height: 30px;
}

.switch-button input {
    padding: 10px 0px;
    width: 55px !important;
}

/* search  */
.g-search .col-6 {
    width: 48% !important;
}

.g-search .half-table {
    border: 1px solid rgba(33, 33, 33, 0.12);
    padding: 20px 20px 2px 20px;
    border-radius: 10px;
    height: fit-content;
    position: relative;
}

.g-search .left-balance {
    justify-content: space-around;
}

/* .g-search .left-balance ul{
    padding: 0px 10px !important;
} */
.g-search .hashtag-list-card {
    border: none;
    padding: 12px 12px 12px 8px;
    background: #F8F8F8;
    border-radius: 5px;
}

.g-search .hashtag-list-card .form-check-input[type=checkbox] {
    border-radius: 0.25em;
    border-color: rgba(0, 0, 0, .25);
}

.right-screen .selectedAssetActions {
    padding: 4px 16px;
    background: rgba(69, 188, 210, 0.08);
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}

.g-search .hashtag-list-card.selected-card {
    background: rgba(69, 188, 210, 0.08);
    box-shadow: none;
    border-radius: 5px;
}

.g-search .hashtag-list-card .form-check-input:focus {
    border-color: rgba(0, 0, 0, .25);
    outline: 0;
    box-shadow: none;
}

.hashtag-list-card .form-check-input:checked {
    background-color: #ffffff;
    border-color: #45BCD2;
}

.g-search .hashtag-list-card.selected-card .hashtag-name {
    color: #45BCD2;
}

.title-main {
    cursor: default;
}

.hashtag-list-card.selected-card .form-check-input:checked {
    background-color: #ffffff;
    border-color: #45BCD2;
    color: #45BCD2;
    background-image: url("../../assets/setup-icon/check.svg");
}

.g-search .hashtag-list-card .gsearch-align {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
    text-align: right;
}

.gSearch-right-title {
    margin: 10px 0px 23px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}

.g-search .toggle-two-button ul {
    border: none;
}

.g-search .toggle-two-button ul li {
    text-align: center;
    margin-right: 10px;
    padding: 0px 5px;
    width: auto;
    cursor: pointer;
    color: rgba(33, 33, 33, 0.75);
}

.g-search .toggle-two-button ul li h5 {
    font-family: 'Inter';
    font-style: normal;
    color: rgba(33, 33, 33, 0.5);
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    padding: 5px 0px;
    margin: 0;
}

.g-search .toggle-two-button ul li .active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    margin: 0;
    border-bottom: 2px solid #45BCD2;
    color: #45BCD2 !important;
}

.free-style-btn {
    position: absolute;
    z-index: 9;
    right: 18px;
    top: 28px;
}

.free-style-btn span {
    color: #45BCD2;
    cursor: pointer;
}

.modal-header .btn-close {
    font-size: 10px;
}

/* .modal-open{
    padding-right: 15px!important;
} */
/* .g-search .toggle-two-button ul .active {
    border: none !important;
    background: #45BCD2;
    color: #fff;
} */

.g-search .toggle-two-button ul .active h5 {
    color: #fff;
}

.g-search .approve-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 8px;
    background-color: #64B6E7;
    color: #fff;
    line-height: 24px;
    border: 1px solid;
    border-radius: 50px;
}

.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* userManagement */
.right-screen .top-heading-bar {
    padding: 4px 0px 4px 63px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.right-screen .top-heading-bar ul {
    list-style: none;
    padding: 0;
    display: inline;
    margin-right: 10px;
}

.right-screen .top-heading-bar ul li {
    list-style: none;
    padding: 0;
    display: inline-block;
    margin-right: 10px;
}

.pr-category {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10.0625px;
    line-height: 23px;
    width: 23px;
    height: 23px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50%;
    color: #fff;
    background: #F6971A;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.edu-category {
    width: 23.58px;
    height: 23.58px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10.0625px;
    line-height: 24px;
    display: inline-block;
    background: #FDEAD1;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    color: #96611B;
    position: relative;
    left: -4px;
}

.add-num-category {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.3);
}

.mail-container {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
    cursor: default;
}

.num {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    cursor: default;
}

.top-heading-bar ul li span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #646464;
    cursor: default;
}

.ms-6 {
    margin-left: 4rem !important;
}

.list-card-tags {
    cursor: default;
}

.edit-partner {
    width: 90%;
    margin: auto;
}

.edit-partner .main-title {
    font-family: "Inter";
    font-style: normal;
    /* margin: 20px 0px; */
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #212121;
}

.edit-partner .urn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(33, 33, 33, 0.75);
}

.edit-partner .col-6 p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgba(33, 33, 33, 0.75);
}

.edit-partner .col-6 input {
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 6px;
}

.edit-partner .col-6 .drop-down input {
    padding: 7px 16px;
    margin: 0;
    background: #ffffff;
    border: none;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.edit-partner .col-6 .drop-down .singleSelect {
    border: 1px solid rgba(33, 33, 33, 0.12);
}

.edit-partner .add-phone {
    background-color: white;
    color: rgba(33, 33, 33, 0.75);
    font-weight: 400;
    border: 1px #fdc400 solid;
}

.edit-partner hr {
    border: 1px solid rgba(33, 33, 33, 0.12);
    background-color: unset !important;
}

.edit-partner .delete-border {
    border-right: none !important;
}

.edit-partner .cursor-pointer {
    cursor: pointer;
}

.edit-partner #input-group-dropdown-1 {
    margin-right: 10px !important;
    /* width: 10px; */
    text-align: left;
    border: 1px solid rgba(33, 33, 33, 0.12);
}

.edit-partner .bg-input-dark {
    background: rgba(33, 33, 33, 0.12) !important;
}

.edit-partner .m20 {
    margin: 20px 0px;
}

.setup-category ul .tabactive span {
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    font-feature-settings: "liga" off;
    color: #45bcd2 !important;
    border-bottom: 2px solid #45BCD2;


}

.setup-category ul .tabunactive span {
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    font-feature-settings: "liga" off;
    color: #45bcd2 !important;
}

.edit-partner .w100 {
    width: 100% !important;
}

.edit-partner .w60 {
    width: 60% !important;
}

.edit-partner .w30 {
    width: 30% !important;
}

.edit-partner .w20 {
    width: 20% !important;
}

.edit-partner .multiSelectTag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.reactive-partner input,
.partner-category .my-3 input {
    border-radius: 6px;
    padding: 12px 16px;
}

.reactive-partner.delete-popup .btn-primary,
.partner-category .btn-primary {
    font-size: 15px;
    padding: 8px 20px;
    line-height: 20px;
    border-radius: 5px;
    background-color: #45BCD2;
    border: none;
}

.partner-category .form-label {
    margin-right: 25px;
}

.edit-partner .delete-add {
    position: absolute;
    right: 3px;
    border: none;
    top: 10px;
}

.edit-partner .checkbox {
    margin-right: 10px !important;
}

.edit-partner .chip {
    /* color: white !important; */
    padding: 5px 12px;
    text-transform: capitalize;
    /* margin-bottom: 0px !important; */
    margin-top: 4px;
}

.edit-partner .cursor-pointer {
    cursor: pointer;
}

.edit-partner .searchWrapper {
    border: 1px solid rgba(33, 33, 33, 0.12) !important;
}

.edit-partner input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.toggle-switch {
    width: 6%;
    line-height: 10px;
}

.form-check-input:checked {
    background-color: #64B6E7;
    border-color: #64B6E7;
}

.note {
    font-size: 10px;
    font-family: inter;
    font-weight: 400;
    color: #f24848;
}

.order-count {
    margin: auto;
    text-align: center;
    justify-items: center;
}

.heirarchy-bordering {
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-radius: 20px;
    padding: 15px 0px;
}

.page-item .page-link .sr-only {
    display: none;
}

.talic-forgot {
    text-align: left;
    cursor: pointer;
}

.form-label {
    min-width: 190px;
}

.smartFolder-multiSelect {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.selectbox-maxsize {
    width: 100%;
}

.smartFolder-multiSelect .form-check-label {
    margin-left: 3px;
}

.designation-partner {
    color: white;
    background: #45BCD2;
    padding: 4px 10px;
    border-radius: 14px;
}


/* ////////////////////LeaderBoard//////////////// */

.add-leads {
    background-color: #45BCD2;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 10px 1px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;

}





/* .lead-channel .searchWrapper .icon_down_dir {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
} */

.numeric-score-input {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border-radius: 0px;
    padding: 11px 11px;
}

.lead-row .searchWrapper {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
    border-radius: 0px;
    padding: 8px 12px;
    margin: 0;
}

.lead-row .closeIcon {
    display: none;
}

.lead-row .delete-icon {
    position: relative;
    top: 15px;
}

.lead-row .delete-icon div {
    padding: 0px 30px;
}

.lead-row .table tr {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}

.lead-row .delete-icon .edit-icon {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #64B6E7;
    cursor: pointer;
}

.lead-row .chip {
    background: #fff !important;
    font-size: 16px;
    margin: 0;
}

/* .lead-row .add-leads{
    background: #45bcd2;
    font-size: 30px;
    padding: 6px 8px;
    line-height: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    float:right;
    cursor: pointer;
    border-radius: 50%
} */
.lead-channel {
    width: 30%
}

.lead-condition {
    width: 29%
}

.lead-score-points {
    width: 30%
}

/* ////////////////////////////////////////////////////////// */

.export-btn-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 5px;
    top: -1px;
    position: relative;
}

.leader-report .top-heading-bar {
    padding: 4px 0px 4px 4px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.top-heading-bar ul{
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.column-text-color {
    color: rgba(33, 33, 33, 0.75);
}

.top-heading-bar ul li img{
    margin-left: 4px;
    width: 18px;
    margin-top: -2px;
}
.tootltip-style{
    margin:0px;
    font-size:10px;
}

.leader-report-top-title h3{
    border-bottom: 1px solid #e2e2e2;
    color: rgba(33,33,33,.75);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
    padding: 0 0 20px;   
}
@media screen and (max-width: 786px) {
    .scrollable-table{
        overflow-x: scroll !important;
    }
    .scrollable-width{
        width: 650px !important
    }
    .leader-report-top-title{
        margin-top: 30px;
    }
    .leader-report-top-title h3{
        padding: 0px 15px 30px;
    }
}

/* report table */

.report-row-count{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    width: 10%;
    color: rgba(33, 33, 33, 0.75);
    cursor: pointer;
}

.level-styling{
    flex: 0 0 auto;
    text-align: center;
    width: 12%;
    cursor: pointer;
}

.level-styling img{
    width: 30px;
}

.level-inside{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #64B6E7;
    cursor: pointer;
}

.report-common{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    flex: 0 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.report-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 26%;
    text-align: center;
    color: rgba(33, 33, 33, 0.75);
    /* line-height: 24px; */
}

.report-points{
    width: 13%;
    text-align: center;
}

.report-code{
    width: 16%;
    text-align: center;
}

.report-branch{
    width: 21%;
}
.report-border-bottom{
    border-bottom: 1px solid rgba(33, 33, 33, 0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
}

.report-similar-prop{
    padding: 0px 10px !important;
    text-align: center;
}

#popover-basic{
    inset: 0px auto auto 70px !important;
    width: 70%;
    max-height: 350px!important;
    z-index: 9
}

.rank-holder-highlight{
    box-shadow: 0px 0px 12px #45BCD2;
}

.how-to-earn{
    border-top: 1px solid black;
    margin-top: 5px;
}
/* report table */

.digicard_form_title{
    font-size: 16px;
    font-weight: 600;
}

.digicard_form_button{
    background-color: #45BCD2;
    border: none;
}

.digicard_form_button:hover{
    background-color: #45BCD2;      
    box-shadow: 0px 0px 12px #45BCD2;
}

.listing-faq-benefit {
    overflow: hidden; /* Prevent scroll on the container */
    height: 100%; /* Keep full height, controlled by .droppable-container */
}

.droppable-container {
    overflow-y: auto;  /* Apply scroll here */
    max-height: 65vh;
}

.listing-faq-benefit .count {
    font-size: 24px;
    font-weight: bold;
}

.listing-faq-benefit h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
}

.listing-faq-benefit p {
    font-size: 10px;
    font-weight: 700;
    color: gray;
    margin-bottom: 0;
}

.scroll-deactivate{
    overflow: hidden !important;
}

.listing-modal .modal-dialog{
    max-width: 60% !important;
}

.listing-modal .modal-content{
    height: 80vh;
}

.edit-benefit{
    padding: 10px 150px;
}

.edit-benefit-title{
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
}

.benefit-back-btn{
    margin-bottom: 20px;
}

.empty-table{
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 40%;
    margin: 0 !important;
}
.edit-partner .searchWrapper {
    padding-left: 0px !important;
}