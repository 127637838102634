h5 {
  font-size: 18px;
  font-weight: 400;
}

.more-filters .form-select {
  width: 160px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.75);
  display: inline;
  margin-right: 20px;
}

.reorder-title{
    padding: 5px 45px;
    border-radius: 50px;
    font-size: 15px;
    border: 1px solid #ced4da;
    color: rgba(33, 33, 33, 0.75);
    line-height: 30px;
    cursor: pointer;
}

.reorder-title:hover{
    padding: 5px 45px;
    border-radius: 50px;
    font-size: 15px;
    border: 1px solid #45bcd2;
    color: #45bcd2;
    background: rgba(69, 188, 210, 0.08);
    line-height: 30px;
    cursor: pointer;
}

.reorder-title.active{
    border: 1px solid #45bcd2 !important;
    color: #45bcd2 !important;
    background: rgba(69, 188, 210, 0.08) !important;
}

.more-filters .btn.btn-outline-info {
  width: 160px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(33, 33, 33, 1);
  border: 1px solid #ced4da;
  text-align: left;
}

.add-more-filter {
  line-height: 0;
  font-size: 18px;
  float: right;
  color: rgba(33, 33, 33, 1);
}

.sort-by .title {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
  float: left;
  line-height: 30px;
}

.sort-by .form-select {
  width: 100px;
  border-radius: 25px;
  font-size: 12px !important;
  font-weight: 500;
  color: #45bcd2;
  border: 0;
}

.toggle-btn {
  padding: 6px 6px;
  line-height: 0em;
  font-size: 16px;
}

.toggle-btn.btn.btn-outline-secondary {
  color: rgba(33, 33, 33, 1);
  border-color: #ced4da;
}

.toggle-btn.btn.btn-outline-secondary.active {
  color: #45bcd2;
  background: rgba(69, 188, 210, 0.08);
}

.toggle-btn.btn.btn-outline-secondary:hover {
  color: #45bcd2;
  background: rgba(69, 188, 210, 0.08);
}

.selectedAssetActions {
  padding: 4px 16px;
  background: rgba(69, 188, 210, 0.08);
  border-radius: 5px;
  margin-bottom: 20px;
}

.selectedAssetActions ul {
  margin: 0;
  padding: 0;
}

.selectedAssetActions ul,
.selectedAssetActions ul li {
  list-style: none;
  padding: 0;
  display: inline;
  margin-right: 10px;
}

.selectedAssetActions ul li .btn-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  border-radius: 20px;
  line-height: 0;
  font-size: 12px;
  margin: 6px 0;
}

.selectedAssetActions ul li .btn-light:hover {
  background-color: rgba(69, 188, 210, 0.1);
  border-color: rgba(69, 188, 210, 0.1);
}

.selectedAssetActions ul li .btn-light span {
  color: #45bcd2;
  font-size: 14px;
  top: -1px;
  position: relative;
  margin-right: 4px;
}

.selectedAssetActions ul li .btn-light.selected {
  background-color: transparent;
  border-color: transparent;
}

.selectedAssetActions ul.float-right {
  float: right;
  margin-right: 0;
  padding-right: 0;
}

.selectedAssetActions ul.float-right li:last-child {
  margin-right: 0;
}

.no-data.asset {
  position: absolute;
  width: auto;
  margin-left: 30%;
  margin-top: 12%;
}

ul.share-options {
  margin: 0;
  padding: 0;
}

ul.share-options li {
  list-style: none;
  margin-bottom: 1rem;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}

ul.share-options li > div {
  display: inline;
  font-size: 13px;
}

ul.share-options li > div:first-child {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

ul.share-options li > div:last-child {
  float: right;
  line-height: 0;
  font-size: 12px;
  margin-top: 4px;
}

ul.share-options li:hover {
  border: 1px solid #45BCD2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  background: linear-gradient(0deg, rgba(69, 188, 210, 0.08), rgba(69, 188, 210, 0.08)), #FFFFFF;
}

.share-asset-btn {
  float: left;
  padding: 9px;
  margin-bottom: 10px;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 10px;
  margin-right: 10px;
}

.share-asset-btn:hover {
  border: 1px solid #45BCD2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  background: linear-gradient(0deg, rgba(69, 188, 210, 0.08), rgba(69, 188, 210, 0.08)), #FFFFFF;
}

.alert-icon {
  font-size: 38px;
  color: red;
  line-height: 0;
  margin-bottom: 10px;
}
@media (max-width:776px) {
  .more-filters select {
    width: 100% !important;
}
.padding-left-0{
  padding-left: 0 !important;
}
.padding-right-0{
  padding-right: 0 !important;
}
.more-filters, .sort-by{
  padding: 0 1.5rem;
}
.more-filters select {
  margin: 0.5rem 0;
}
.sort-by .title , .sort-by select {
  font-size: 16px !important;
  font-weight: 600;
  margin: 0.5rem;
}
.custom-padding > div {
  padding: 0;
}
.custom-padding {
   padding: 1rem;
   margin: 0 !important;
} 
.pagination {
  justify-content: center;
}
ol.breadcrumb .ms-auto {
  margin: 0 !important;
}
}


.quicky{
  font-size: 12px;
  font-weight: 700;
  color: rgba(33, 33, 33, 0.75);
}