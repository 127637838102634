.folder-asset-details.container > div > div:first-child {
  padding: 0;
}

.folder-asset-details.container > div > div:first-child > div {
  border: 1px solid rgba(33, 33, 33, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 406px;
  padding: 40px;
  margin-right: 40px;
}

.folder-asset-details.container > div > div:last-child {
  border: 0;
  background: #f8f8f8;
  border-radius: 10px;
  min-height: 406px;
}

.folder-asset-details .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(33, 33, 33, 0.75);
}

.folder-asset-details .sub-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}

.folder-asset-details .text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  margin-top: 40px;
}

.folder-asset-details.container > div > div {
  padding: 40px;
}

.tags-hashtags ul {
  padding-left: 0;
  margin-top: 15px;
}

.tags-hashtags ul li {
  list-style: none;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
}

.tags-hashtags ul li.contentType {
  text-transform: uppercase;
}

.tags-hashtags .tags li {
  list-style: none;
  padding: 4px 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 12px;
}

.tags-hashtags .tags li.new-tag {
  color: #d6b922;
  border: 1px solid #d6b922;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}

.tags-hashtags li.hash-tags {
  list-style: none;
  padding: 4px 12px;
  background: rgba(246, 151, 26, 0.08);
  border: 1px solid rgba(246, 151, 26, 0.2);
  box-sizing: border-box;
  border-radius: 48px;
}

.tags-hashtags .tags .vr-divider {
  height: 20px;
  background: transparent;
}

.tags-hashtags .tags .vr-divider div {
  border-left: 1px solid rgba(33, 33, 33, 0.12);
  height: 25px;
}

.published-details > span,
.access-details > span {
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(33, 33, 33, 0.3);
  margin-bottom: 10px;
  display: block;
}

.published-details ul,
.access-details ul {
  padding-left: 0;
}

.published-details ul li {
  list-style: none;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 10px;
}

.published-details ul li span {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.access-details ul li {
  list-style: none;
  white-space: nowrap;
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  text-transform: uppercase;
}

.access-details ul li span {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.access-details .circle {
  padding: 6px 4px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  background: #f6971a;
  color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
}

.access-details .square {
  padding: 6px 4px;
  width: 30px;
  height: 30px;
  text-align: center;
  background: #fdead1;
  color: #96611b;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  font-size: 10px;
}

.folder-asset-details.container .asset-card-tag {
  position: relative;
  float: right;
  font-size: 36px;
  cursor: pointer;
}

.asset-bookmark.check {
  color: #45bcd2;
}

.asset-bookmark:hover, .asset-bookmark.check:hover {
  color: #f6971a;
}

.folder-asset-img {
  text-align: center;
}

.folder-asset-img img {
  /* min-width: 433px; */
  max-width: 433px;
  max-height: 432px;
  margin-top: 40px;
  width: 100%;
}

ul.folder-asset-img-actions {
  position: relative;
  padding-left: 0;
  margin-top: -25px;
}

ul.folder-asset-img-actions li {
  list-style: none;
  display: inline-block;
}

ul.folder-asset-img-actions li .btn-light {
  width: 48px;
  height: 48px;
  right: 243.25px;
  top: 0px;
  background: #ffffff;
  color: #45bcd2;
  border: 1.625px solid rgba(33, 33, 33, 0.12);
  box-sizing: border-box;
  border-radius: 82.875px;
  font-size: 18px;
  line-height: 0;
  margin: 0 5px;
}

ul.folder-asset-img-actions li .btn-light.unpublish {
  padding: 8px 16px;
  position: static;
  width: 100px;
  height: 33px;
  right: 0px;
  top: 7.5px;
  font-size: 14px;
  background: #ffffff;
  color: rgba(33, 33, 33, 0.3);
  border: 1px solid rgba(33, 33, 33, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 60px;
}

ul.folder-asset-img-actions li .btn-light.publish {
  padding: 8px 16px;
  position: static;
  width: 100px;
  height: 33px;
  right: 0px;
  top: 7.5px;
  font-size: 14px;
  background: #ffffff;
  color: #0dcaf0;
  border: 1px solid #0dcaf0;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 60px;
}

.details-image {
  height: 244px;
  width: 432px;
  margin: auto;
}
@media (max-width:992px) {
  .folder-asset-details.container .asset-card-tag{
    font-size: 18px;
  }
  ul.folder-asset-img-actions li .btn-light.unpublish{
    margin-left: 0;
  }
}

@media(max-width:767px) {
  .folder-asset-details {
    margin: 0px !important;
    padding: 0.5rem !important;
  }
  .folder-asset-details .row {
    margin: 0 !important;
  }
  .asset-detail-left  > div{
     margin: 0 !important;
     padding: 1rem !important
  }

  .asset-detail-right {
    margin-top: 2rem;
    padding: 1rem !important;
    min-height: auto !important;
  }
  .asset-detail-right .folder-asset-img {
    overflow: hidden;
  }
  .asset-detail-right .folder-asset-img img{
    object-fit: cover;
    max-width: 100%;
    border-radius: 4px;
    min-width: auto;
  }
  ul.folder-asset-img-actions li .btn-light.publish{
    margin-left: 0;
  }

}