.user-profile.dropdown .btn {
  font-size: 1.2em;
  position: relative;
  color: #45bcd2;
  padding-top: 0;
  padding-left: 2px;
  padding-right: 5px !important;
}

.active-nav {
  color: rgba(33, 33, 33, 1) !important;
}

.user-profile.dropdown .dropdown-toggle::after {
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(33, 33, 33, 0.5);
  /* font-size: 13px; */
  font-size: 11.9px;
  font-weight: 500;
  white-space: nowrap;
}

.fixed-header {
  position: fixed;
  background: #f8f8f8;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.navbar-light .navbar-nav .nav-link .active,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(33, 33, 33, 1);
}

.dropdown-item:active {
  background-color: #45bcd2;
}

.bg-light {
  background-color: transparent !important;
}

.user-profile.dropdown .btn.btn-light {
  background-color: transparent;
  border-color: transparent;
  padding-right: 0;
}

.navbar>.container {
  padding: 0;
}

.dropdown-menu {
  font-size: 13px;
  z-index: 11111;
}

.logout-popup .btn.btn-primary {
  width: 18%;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  background-color: #009ef7;
  border: 1px solid transparent;
  border-radius: 0;
}

.logout-popup .btn-close {
  font-size: 10px;
}

.logout-popup .btn.btn-secondary {
  width: 18%;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0;
  background-color: #e4e6ef;
  color: #3f4254;
  border: 1px solid transparent;
}

.logout-popup .modal-dialog {
  max-width: 500px;
}

.logout-popup .modal-content {
  border-radius: 0px;
}

.logout-popup .modal-title {
  font-size: 14px !important;
}

.logout-popup .modal-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.logout-popup .modal-body {
  padding: 40px 30px;
  color: #181c32;
  font-family: "Roboto", sans-serif;
}

@media (max-width:992px) {
  #basic-navbar-nav {
    position: absolute;
    left: 0;
    background: #f8f8f8;
    width: 100%;
    top: 52px;
  }

  .navbar-toggler:focus,
  :focus-within,
  .navbar-toggler {
    outline: none !important;
    border: none;
    box-shadow: none;
  }

  .dropdown-menu {
    background: #f8f8f8;
  
    padding: 0;
    margin: 0;
  }
.navbar-nav .dropdown-menu {
  border: none;
}
  .dropdown-menu a {
    padding: 0.5rem 0.5rem !important;
    color: rgba(33, 33, 33, 1);
    font-size: 11px;
    font-weight: 500;
  }
}


@media (min-width:576px) {
  #basic-navbar-nav {

    padding-left: clamp(30px, 13vw, 12px);
  }
}


/* Add this to your CSS file */
.dropdown-title {
  display: inline-flex;
  align-items: center;
  border-bottom: 2px solid transparent; /* Line that will appear under title */
  padding-bottom: 2px;
}

.dropdown-title:hover {
  border-bottom-color: #45BCD2; /* Color for the line on hover */
}

.dropdown-icon {
  margin-left: 8px; /* Add space between the title and the icon */
}
