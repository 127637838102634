.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body-container {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 14px;
  color: rgba(33, 33, 33, 1);
  font-weight: 500;
}

.hr-divider {
  display: inline-block;
  width: 100%;
  margin: 40px 0;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

.header-breadcrumb nav {
  margin-top: 20px;
}

.breadcrumb a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  line-height: 2.4em;
}

.breadcrumb-item.active {
  color: #000 !important;
  font-size: 24px;
  font-weight: 300;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

.breadcrumb span {
  line-height: 2em;
  margin: 0 10px;
}

.edit-folder {
  color: #45bcd2;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  color: #45bcd2 !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #45bcd2 !important;
  border-color: #45bcd2 !important;
}

.overlay-bg {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
}

.overlay-white{
  background-color: rgba(255, 255, 255) !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 9999;
  position: fixed;
  top: 42%;
  left: 46%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #45bcd2;
  border-color: #45bcd2 transparent #45bcd2 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  color: #ffffff;
  background-color: #199459;
}

.Toastify__progress-bar--success {
  background: #74c69d !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success svg {
  fill: #74c69d !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  color: #ffffff;
  background-color: #d6201c;
}

.Toastify__progress-bar--error {
  background: #ff8fa3 !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error svg {
  fill: #ff8fa3 !important;
}

.modal-title {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.modal-body {
  font-size: 14px !important;
}

.modal-body .form-select {
  font-size: 14px !important;
}

.sub-header {
  background-color: #f8f8f8;
  padding: 0 6rem 1rem 6rem;
}

.Toastify__toast-body>div:last-child {
  font-size: 12px !important;
}

.css-ik6y5r {
  border-left: 1px solid rgb(0 0 0 / 10%);
}

.user-profile .dropdown-menu[data-bs-popper] {
  left: -120px;
}

.form-select {
  font-size: 14px !important;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #45bcd2 !important;
  width: 6px !important;
}

.ps__thumb-x, .ps__thumb-y {
  background-color: #45bcd2 !important;
  border-radius: 1px !important;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: 1 !important;
  width: 10px;
}

.ps:hover > .ps__rail-x {
  height: 10px;
}

.ps__thumb-x {
  height: 6px !important;
}

.show-pointer{
  cursor: pointer;
}



@media(max-width: 767px) {
  .body-container {
    overflow-x: hidden;
  }
  .sub-header {
    padding: 10px;
  }
  .sub-header ol {
    align-items: center;
  }
  .sub-header div, .sub-header li {
    font-size: 16px !important;
  }
  .sub-header li.active {
    font-weight: 600;
  }
  .hr-divider {
     margin: 20px 0;
  }

}

@media (max-width:992px) {
  .user-profile .dropdown-menu {
    right: 20%;
}

}