.circle-contacts {
  margin: 2px;
  background: #f6971a;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 2px;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  margin-right: 10px;
}

.contacts-name {
  font-weight: 500;
  display: contents;
}

.contacts-view{
  min-height: 100vh;
}

.contacts-data-table > div > nav > span:first-child,
.contacts-data-table > div > nav > div:nth-child(2) {
  display: none;
}

.new-contact .card {
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 11px;
  height: 100%;
}

.contacts-view .action-btn {
  color: #45bcd2;
  cursor: pointer;
  font-size: 14px;
}

.contacts-view .action-btn:hover {
  color: #f6971a;
}

.contacts-view .action-btn:first-of-type {
  margin-right: 20px;
}
