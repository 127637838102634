.campaign .search-field {
    width: 100%;
    /* float: left; */
    font-style: italic;
    /* z-index: 3; */
    position: relative;
    padding: 0.65rem 0.5rem;
    top: -5px;
}

.campaign .head-btn{
    gap: 15px;
    justify-content: end;
    display: flex;
}

.container-set{
    margin: 0;
    padding: 40px 50px 15px;
    background-color: #f8f8f8;
    max-width: 100%;
}

.campaign-row{
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.campaign-title{
    color: #212121;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    line-height: normal;
}

.campaign-toggle-btn{
    height: 35px;
}

.image-div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 10%;
}

.time-date-div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    flex: 0 0 auto;
    width: 7%;
    cursor:auto ;
}

.time-date-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight:bold;
    margin: 0;
}

.time-date-div .disable-date {
    color:gray;
    font-weight: 500;
    margin: 0;
}

.table-name{
    color: #212121;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.adjust-width {
    width: 15% !important;
}

.adjust-width.date {
    width: 15% !important;
}

.right-bar-icon {
    flex: 0 0 auto;
    /* width: 3%; */
    margin-left: 10px;
    cursor: pointer;
}

.right-bar-icon svg{
    font-size: 20px;
}

.right-bar-icon .edit-icon{
    font-size: 13px;
}

.business-head {
    padding: 40px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.custom-button{
    display: flex;
    padding: 12px 15px 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border: 1px dashed #45BCD2;
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-button:hover{
    background: rgba(69, 188, 210, 0.08);
    color: #45BCD2;
    border: 1px dashed #45BCD2;
}   

.save-proceed-btn-run {
    width: 110px;
    text-align: left;
    float: right;
}

.sync-btn {
    width: auto; 
    text-align: left;
    float: none;
}

.sync-btn span {
    margin-left: 6px;
}

.edit-proceed-btn-run{
    width: auto;
    text-align: left;
    background-color: #fff;
    color: #45bcd2;
    border-color: #45bcd2;
    padding: 2px 10px;
}

.save-color{
    background-color:#000;
    border-color: #000;
}

.subject-preview{
    color: #656565;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.form-floating>label{
    z-index: 0 !important;
}

.campaign .multiSelectContainer input {
    background: transparent;
    border: none;
    margin-top: 0px !important;
}

.pick-template-row .no-data.asset, .direct-list .no-data.asset, .list-campaign .no-data.asset {
    position: absolute;
    width: auto;
    left: 50%;
    margin-top: 7%;
    margin-left: 0;
}

.more-filters .dropdown .btn-light{
    margin: 0 !important;
}

.tagline {
    font-size: 22px;
    color: #585858;
}

.selected-card {
    background: #ffffff !important;
    border-color: #45bcd2 !important;
    box-shadow: 0px 0px 10px #45bcd2 !important;
}

.movable-add-button{
    background: #45bcd2;
    border: none;
}

.movable-add-button:hover{
    background: #45bcd2;
    border: none;
}

.movable-add-button:first-child:active, :not(.btn-check)+.movable-add-button:active {
    background: #45bcd2;
    border: none;
}

.campaign-row .dropdown-menu {
    font-size: 13px;
    z-index: 9 !important;
}

.list-popup{
    height: 70vh;
    overflow-y: auto;
}

.listing-container .modal-scroll{
    font-weight: 900;
}

.listing-container .btn-close{
    background-color: gray;
    color: #fff !important;
    padding: 12px;
}

.listing-container .header-color{
    background-color: rgb(237, 237, 237)
}

.listing::-webkit-scrollbar-track {
  background: #f8f8f8;
  -webkit-box-shadow: 0px 0px 0px #f8f8f8;
  border: none;
}
.listing::-webkit-scrollbar-thumb {
  width:1px !important;
  background: #59B3C6;
}
.listing::-webkit-scrollbar-thumb {
  border-radius: 10px;
  scrollbar-width: thin;
}
.listing::-webkit-scrollbar {
  width: 4px;
}

.listing-container .modal-title > span:first-child{
    font-weight: 600;
    font-size: 22px;
    color: #707070;
}

.view-header > span:first-child{
    font-weight: 600;
    font-size: 22px;
    color: #707070;
}

.search-field-list {
    width: 364px;
    font-style: italic;
    position: relative;
    padding: 0.3rem 0.5rem;
}

.search-icon-list {
    position: absolute;
    opacity: 0.5;
    z-index: 9;
    right: 97px;
    top: 0;
}

.review-aligning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: left !important;
    flex: 0 0 auto;
    width: 45%;
}

.right-response{
  background: rgb(142, 188, 189);
  max-width: 75%;
  width: fit-content;
  border-radius: 0px 20px 20px 20px;
  padding: 20px;
  padding-top: 10px;
  position: relative;
}

.first-left-response{
  position: relative;
  margin-right: 0;
  width: fit-content;
  text-align: right;
  background: rgb(153, 159, 159);
  max-width: 50%;
  margin-left: auto;
  border-radius: 20px 0px 20px 20px;
  padding: 20px;
  padding-top: 10px;
}

.review-container .ai-response{
    line-height: 20px;
    font-style: italic;
}

.main-div-theme {
    background: aliceblue;
    padding: 0px 10px 5px 15px;
}

.color-theme{
    background: aliceblue;
    padding-bottom: 0px !important ;
}

.review-container{
    padding: 20px 20px;
    text-align: left;
    line-height: 20px;
}

.top-data{
    gap: 50px;
}

.top-data div{
    background-color: rgb(225, 225, 224);
    width: 140px;
    padding: 15px 0px;
    border-radius: 10px;
    border: 1px solid rgb(210, 210, 210);
    text-align: center;
}

.top-data div p{
    font-size: 16px;
    margin-bottom: 2px;
}

.top-data div h2{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 2px;
}

.middle-data{
    justify-content: space-between;
}

.middle-left{
    background-color: rgb(225, 225, 224);
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid rgb(210, 210, 210);
}

.middle-left h2{
    font-size: 22px;
    margin-bottom: 20px;
}

.middle-left p{
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    padding: 10px 0px;
    margin-bottom: 0;
    border-top: 1px solid rgb(210, 210, 210);
    /* border-bottom: 1px solid rgb(210, 210, 210); */
}

.middle-right{
    background-color: rgb(225, 225, 224);
    border-radius: 10px;
    border: 1px solid rgb(210, 210, 210);
}


.recharts-wrapper{
    width: 100% !important;
}

.footer-report-data{
    background-color: rgb(225, 225, 224);
    border-radius: 10px;
    border: 1px solid rgb(210, 210, 210);
    padding: 20px;
}

.footer-report-data .head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(210, 210, 210);
}

.footer-report-data .head p{
    font-size: 22px;
}

.icon-report{
    font-size: 20px;
}

.footer-report-data .list{
    display: flex;
    border-bottom: 1px solid rgb(210, 210, 210);
    align-items: center;
}

.report-review{
    font-weight: 300;
    font-size: 10px;
}

.width-input{
    width: 80%;
}

.business-save-btn {
    width: 130px;
    text-align: left;
    font-size: 14px;
    float: right;
}

.review-icon-dim{
    font-size: 20px;
}

.reply-response{
    font-size: 10px !important;
    font-style: italic;
    line-height: 13px;
}

.no-comment{
    font-size: 10px ;
}

.notify-reviews{
    font-weight: 200;
    font-size: 10px;
    display: flex;
    margin-right: 0;
    margin-left: auto;
}

.write-review{
    font-size: 16px;
    text-transform: none;
}

.border-review{
    border: 1px solid #45bcd230;
}

.review-action-outer .edit-icon svg:hover{
    color: #45bcd2;
}

.review-ellipse{
    white-space: nowrap;       /* Prevent text from wrapping to a new line */
    overflow: hidden;          /* Hide any overflowing text */
    text-overflow: ellipsis
}

.main-setup-container {
    padding: 0px 15px 0px;
}

.circle-title{
    font-size: 1rem;
    background-color: #45bcd2;
    padding: 14px;
    color: white;
    border-radius: 100%;
    line-height: 1.5rem; 
    font-style: normal; 
    font-weight: 600; 
    line-height: 1.5rem; 
    text-align: center; 
    text-transform: capitalize; 
}

.circle-div {
    font-family: 'Inter';
    font-style: normal;
    /* font-weight: 500;
    font-size: 22px; */
    line-height: 70px;
    flex: 0 0 auto;
    width: 10%;
}

.business-status{
    padding: 5px 12px;
    color: white;
    border-radius: 50px;
}

.business-status.blue{
    background-color: #437983;
}

.business-status.yellow{
    background-color: rgb(130, 130, 83);
}

.read-more-gmb{
    color: #348391;
    cursor: pointer;
    font-size: 10px;
}

.read-more-gmb:hover{
    color: #348391;
    text-decoration: underline;
}