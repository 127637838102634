.smart-folder {
  max-height: 142px;
  max-width: 164px;
  display: block;
  float: left;
  margin-right: 20px;
}

.shimmer-folder-text {
  height: 20px;
  position: absolute !important;
  width: 45%;
  left: 18px;
  bottom: 12px;
}

.smart-folder-container {
  display: block;
  background-image: url(../../../../assets/folder.png);
  height: 142px;
  width: 164px;
  background-size: 100%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* .bottom-to-top-effect{
  background: linear-gradient(to top, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%) !important;
} */
.smart-folder.card {
  border: 0;
  background-color: transparent;
}

.folder-name {
  position: absolute;
  bottom: -2px;
  left: 32px;
  font-size: 14px;
  color: rgba(33, 33, 33, 1);
  font-weight: 500;
}

.file-counts {
  font-size: 14px;
  position: absolute;
  right: 0px;
  bottom: 40px;
  color: rgba(33, 33, 33, 0.3);
}

.folder-tag {
  position: absolute;
  background-image: url(../../../../assets/folder-tag.png);
  height: 18px;
  width: 18px;
  background-size: 100%;
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.folder-tag-2 {
  position: absolute;
  background-image: url(../../../../assets/folder-tag2.png);
  height: 18px;
  width: 18px;
  background-size: 100%;
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.folder-files {
  position: absolute;
  top: 28px;
  z-index: -1;
}

.folder-files div:first-child {
  width: 150px;
  margin-left: 7px;
  height: 80px;
  background-color: cadetblue;
  border-radius: 7px;
  z-index: 2;
  position: relative;
  background-size: 100% 100%;
}

.folder-files div:nth-child(2) {
  width: 140px;
  margin-left: 12px;
  margin-top: -92px;
  height: 80px;
  background-color: brown;
  border-radius: 7px;
  z-index: 1;
  background-size: 100% 100%;
}

.create-new-folder {
  width: 164px;
  /* height: 142px; */
  text-align: center;
  font-size: 14px;
  border: 2px dashed #45BCD2;
  cursor: pointer;
  padding: 47px 0;
  background-color: rgba(69, 188, 210, 0.08);
  color: #45BCD2;
  font-weight: 500;
}

.create-new-folder input {
  display: none;
}

.create-new-folder .add-icon {
  display: block;
}

.folder-list-container {
  display: flex;
}

.folder-list-wrapper {
  display: flex;
  overflow-x: scroll !important;
  height: 195px;
}

.folder-list-wrapper a {
  cursor: pointer;
}

@media(max-width:767px) {
  .folder-list-container {
    /* flex-direction: column; */
  }
  .create-folder-wrapper {
     display: flex;
     flex-direction: column;
     align-items: center;
  }
}