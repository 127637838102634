.top-header-cta {
    display: flex;
    list-style: none;
    padding: 0px 63px;
}

.dropdown-toggle::after {
    display: none;
}

.btn.show {
    color: white;
}

.final-button {
    font-size: 15px;
    padding: 8px 20px;
    line-height: 20px;
    border-radius: 5px;
    background-color: #103880;
    border: none;
}

.modal-heading-customise {
    color: #212121;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
}

.form-label-popup .div-inside-popup {
    display: flex;
    margin-bottom: 15px;
}

.form-label-popup .form-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* margin: 0; */
    margin: 10px 0px;
    color: #7b7f83;
    cursor: default;
}

.multi-div-target>div {
    width: 100%;
    height: 50px;
}

.form-label-popup .form-control {
    background: #fff;
    border: 1px solid #cecece;
    /* border-radius: 6px; */
    padding: 12px 16px;
    height: 44px;
}

.form-label-popup input {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.search-wrapper.searchWrapper.singleSelect {
    background: #fff;
    border: 1px solid #cecece;
    border-radius: 6px;
    padding: 9.5px 16px;
}

.chip.singleChip {
    background-color: white !important;
    color: black;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 0;
}


.create-content .search-wrapper.searchWrapper.singleSelect {
    background: #fff;
    border: 1px solid #cecece;
    border-radius: 6px;
    padding: 6.5px 10px;
}

.create-content .chip.singleChip {
    background-color: white !important;
    color: black;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px;
    padding: 0;
    margin: 0;
}

.create-content .icon_down_dir::before {
    position: absolute;
    display: none;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}

.multiSelectContainer input{
    margin-top: 0px !important;
}

.icon_cancel.closeIcon {
    display: none;
}

.preview-div {
    margin-top: 16px;
    width: 100%;
    padding: 2px 0px 0px;
    border-top: 1px solid #cecece;
    /* border-bottom: 1px solid #cecece; */
    /* border-radius: 5px; */
}

.preview-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    /* margin: 0; */
    margin-bottom: 5px;
    color: #7b7f83;
    cursor: default;
}

.form-control.form-control-color {
    padding: 6px 230px 6px 6px;
    max-width: 100%;
}

.div-inside-popup>span {
    position: absolute;
    left: 265px;
    margin-top: 13px;
    font-size: 16px;
    background-color: white;
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.dropdown-menu a {
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    color: #7b7f83;
    cursor: default;
}

.dropdown-menu a svg {
    margin-right: 5px;
    font-size: 13px;
}

.cta-heading {
    border-bottom: 1px solid #e2e2e2;
    color: #a1a5b7;
    font-weight: 500 !important;
    font-family: Inter;
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
    padding: 0 0 20px;
}

.video-view-input {
    position: relative;
    border: 1px solid #cecece;
    padding: 0px 10px;
    height: 35px;
    border-radius: 5px;
    margin-top: 5px;
}

.view-modal span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.view-modal button {
    padding: 16px 12px;
}

.view-button {
    font-size: 15px;
    cursor: pointer;
}

.view-button:hover {
    color: #4939d5;
}

.post-fix {
    padding: 4px 0px;
    font-size: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #757575;
}

.pre-fix {
    padding: 4px 0px;
    font-size: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #757575;
}

.main-placeholder {
    height: 37px;
    font-size: 18px;
    line-height: 20px;
    color: #cecece;
}

/* /////VideoCta////////////// */
.videoCtaContainer {
    /* background-color: white;*/
    height: 100vh !important;
}

.h-25v {
    height: 12vh !important;
}

.h-50v {
    height: 400px !important;
}

.paddingDiv {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important;
    color: #909294;
}

.paddingDivAfter {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    color: #909294;

}

.videocta-wrap .nameBlock {
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
    padding: 20px;
    height: 95px !important;
    margin-bottom: 2.5rem !important;
    background: #fff;
}

.videoCtaContainer .name,
.videocta-wrap .nameBlock {
    font-size: 21px;
    font-weight: 600;
    color: #3f4254 !important;
}

.videoCtaContainer .inputTime {
    width: 10%;
    padding: 0 0 0 8px !important;
    font-size: 10px;

}

.videoCtaContainer .w-10 {
    width: 10%;
    /* background-color: #f8f8f8; */
}

.lowerSection {
    width: 100%;
}

.lowerSection .firstBlock,
.secondBlock,
.thirdBlock {
    background-color: #f8f8f8;
    padding: 10px 20px;
}

.firstBlock {
    height: 50px !important;
    cursor: pointer;
}

.stickyPosition {
    position: sticky !important;
    background: #f8f8f8;
    padding-bottom: 10px;
}

.videocta-wrap .blockBorder {
    border-right: 10px solid #f5f8fa;
}

.blockFontSize {
    font-size: 1rem !important;
}

.firstBlock .dropdown {
    min-width: 100px;
}

.firstBlock .dropdown-btn {
    font-size: 16px;
    background: white;
    border: 1px solid black;
    border-radius: 4px;
    height: 40px;
    width: auto;
    padding: 0 30px;
    cursor: pointer;
}

.firstBlock .dropdown-btn:hover,
.dropdown-btn:focus {
    background: #f8f8f8;
}

.firstBlock .dropdown-items {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    width: 150px;
    position: absolute;
    margin-top: 10px;
    margin-left: 1px;
    display: flex;
    flex-direction: column;
    padding: 8px 15px;
    background-color: #f8f8f8;
}

.firstBlock .dropdown-item {
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
}

.firstBlock .dropdown-item:not(first-child) {
    padding-top: 15px;
    padding-bottom: 15px;
}

.firstBlock .dropdown-item:not(last-child) {
    padding-top: 15px;
    padding-bottom: 15px;
}

.isVisible {
    visibility: visible;
}

.isHidden {
    visibility: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.thirdBlock .colorInput {
    position: absolute;
    top: 6px;
    left: 60px;
    /* margin-top: 6px; */
    font-size: 16px;
    background-color: white;
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.w-60 {
    width: 60%;
}



/* CTA 24/4/2023 */
.cta-wrap .search-inside-input {
    border: 1px solid #e4e6ef !important;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.cta-wrap .search-icon {
    position: absolute;
    opacity: 0.5;
    z-index: 111;
    right: 16px;
    top: 12px;
}

.cta-wrap .search-inside-input::placeholder {
    color: #a1a5b7 !important;
}

.cta-wrap .top-heading-bar ul li span {
    font-size: 13px;
    line-height: 24px;
    cursor: default;
    color: #a1a5b7;
    font-weight: 600 !important;
}

/* .cta-wrap .top-header-cta{
    padding: 0;
} */
.cta-wrap .table-name span {
    color: #a1a5b7 !important;
    font-size: 12.5px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: left;
}

.cta-wrap .edit-icon svg {
    font-size: 16px;
    color: #a1a5b7;
    margin: 5px 2px;
}

.cta-wrap .flex-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly !important;
}

.cta-wrap .row.border-bottom {
    border-bottom: 1px solid #f5f8fa !important;
    padding: 2px 0;
    background: #fff;
}

.cta-wrap .pagination {
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100%;
}

.cta-wrap .full-width {
    width: 100% !important;
}

.padding-right {
    padding-right: 10px;
}

.cta-wrap .page-item.active .page-link {
    background: #009ef7 !important;
    border: 1px solid #009ef7 !important;
    color: #fff !important;

}

.cta-wrap .pagination a.page-link {
    color: #337ab7 !important;
    font-weight: 400;
    border: 1px solid #ddd;
    font-size: 14px;
}

.cta-wrap .pagination .page-item .page-link {
    padding: 6px 12px !important;
}

.cta-wrap .top-header-cta {
    padding: 0px 70px 0 50px;
}

.cta-wrap .subheader {
    margin-bottom: 20px;
}

.cta-wrap .pagination {
    margin-top: 40px !important;
}

.dropdown-menu a:hover {
    cursor: pointer;
}

.subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.modal-dialog .btn.final-button {
    background: #009cf7a9;
}


@media (max-width:767px) {
    .cta-wrap .table>:not(caption)>*>* {
        padding: 0 !important;
    }

    .padding-right {
        padding: 0;
    }

    .cta-wrap .subheader {
        flex-direction: column;
    }

    .cta-wrap .search-inside {
        width: 91%;
    }

    .cta-wrap .right-buttons {
        display: inline-flex;
        width: 100%;
    }

    .cta-wrap .table.table-responsive.borderd {
        padding: 0 26px;
        padding-right: 10px;
    }

    .cta-wrap .subheader {
        margin-top: 28px;
    }

    .cta-wrap .top-header-cta {
        padding: 0 12px;
    }
}


.videocta-wrap .secondBlock {
    height: auto !important;
}

.videocta-wrap .firstBlock {
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
    height: 55px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.lowerSection .firstBlock,
.secondBlock,
.thirdBlock,
.videocta-wrap .firstBlock .dropdown-items {
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
    background: #fff;
}

.lowerSection {
    position: relative;
}

.videocta-wrap .firstBlock .dropdown-items {
    top: 57px;
    left: -13px;
    max-width: 150px;
    width: 100%;
}

.videocta-wrap .dropdown-item:active {
    background-color: #f3fdff;
}

.videocta-wrap .dropdown-item:focus-visible {
    outline: none;
}

.videocta-wrap .blockFontSize {
    color: #3f4254;
    font-weight: 500;
    font-size: 16.25px;
}

.videocta-wrap .paddingDivAfter {
    color: #3f4254 !important;
}

.videocta-wrap .form-label {
    color: #3f4254;
}

.custom-bg-light {
    background: #f5f8fa;
    max-width: 100%;
}

.videocta-wrap .end-content {
    font-size: 14px;
    color: #3f4254 !important;
}

.firstBlock .dropdown-item:not(last-child) {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 13px;
    color: #3f4254 !important;
    font-weight: 500;
}

.videocta-wrap .form-control {
    font-size: 14px;
    font-weight: 500 !important;
    color: #3f4254 !important;
}

.videocta-wrap .secondBlock.col-5.blockBorder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@media (max-width:576px) {

    .videocta-wrap .secondBlock,
    .videocta-wrap .thirdBlock {
        margin-top: 16px !important;
    }

    .custom-bg-light {
        padding: 0 12px !important;
    }
}

@media (max-width:992px) {
    .videocta-wrap .thirdBlock {
        margin-top: 16px !important;
    }

    .videocta-wrap .fixed-button {
        bottom: -15px !important;
        right: 30px !important;
        position: fixed;
    }

    .end-content {
        margin-left: 0 !important;
    }
}