.file-upload{
    /* position: absolute;  */
    /* top: 0; 
    left: 0; 
    height: 100%;  */
    background-color: transparent; 
    /* width: 85%; */
}

.file-upload-parent{
    display: flex; 
    position: relative; 
    justify-content: center; 
    align-items: center; 
    border: none;
    background-color: transparent; 
}

.box{
    background-color: rgb(220, 220, 220);
    padding: 10px;
    border-radius: 10px;
    height: 50;
    border: 1px solid rgba(0, 0, 0, 0.125);;
    width: 50px;
    /* display:block; */
}

.my-div-bg {
    width: 100px; /* Set the width of the div */
    height: 120px; /* Set the height of the div */
    background-image: url('../../../../assets/pdf-img.png'); /* Path to your image */
    width: 124px !important;
    height: 120px !important;
    font-size: 10px !important;
    box-shadow: none !important;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 8%) !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-size: 100%;
    background-position: 0px -25px;
    background-color: rgba(106, 101, 101, 0.125) !important;
    border-radius: 0.50rem;
    background-size: cover; 
    background-repeat: no-repeat; /* Prevents the image from repeating */
    /* padding-top: 80px; */
    padding: 5% 0;
}

.my-div-bg p {
    font-size: 10px;
    margin-bottom: 0px;
}

.cross-pdf{
    background-color: rgb(134, 134, 134);
    padding: 3px;
    border-radius: 100%;
    font-size: 16px;
    top: 5px;
    right: 5px;
    color: black;
}

.tick-pdf{
    background-color: rgb(25, 134, 31);
    padding: 1px;
    border-radius: 100%;
    font-size: 12px;
    top: 16px;
    left: 16px;
    color: black;
    transform: rotate(180deg);
}

.error-pdf{
    background-color: rgb(166, 2, 2);
    padding: 2px;
    border-radius: 100%;
    font-size: 12px;
    top: 16px;
    left: 16px;
    color: rgb(166, 2, 2);
    transform: rotate(180deg);
}

.folder-tag.enhance-tag, .folder-tag-2.enhance-tag{
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    transform: rotate(180deg);
}

.attach-button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #45bcd2;
    border: 1px solid #45bcd2;
    padding: 0.375rem 0.75rem;
    margin-top: 20px;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.overlay-broucher {
    position: absolute;
    background-color: rgb(186, 186, 186);
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0.50rem;
    opacity: 0.7;
    z-index: 99
}

.overlay-spinner{
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 999;
}

.disable_scan{
  background-color: #eee !important;
  border: none !important;
  color: rgb(153, 153, 153) !important;
  cursor:no-drop !important;
}