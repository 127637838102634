.create-folder.container {
  border: 1px solid rgba(33, 33, 33, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 406px;
}

.create-folder .title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  width: 261px;
}

.create-folder .text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.75);
  width: 261px;
  display: block;
}

.create-folder.container .row .col {
  padding: 60px;
  padding-bottom: 0;
}

.create-folder.container .btn-info {
  width: 201px;
  text-align: left;
  float: right;
  position: relative;
  margin-bottom: 16px;
}

.create-folder.container .btn-info span {
  position: relative;
  top: -2px;
  float: right;
}

.create-folder.container .form-check.form-switch {
  padding: 14px 16px;
  /* max-width: 179px; */
  height: 48px;
  border: 1px solid #ced4da;
  color: rgba(33, 33, 33, 0.75);
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding-right: 0;
  padding-left: 62px;
  width: 100%;
}

.form-check-input:checked {
  background-color: #f6971a;
  border-color: #f6971a;
}

.smartFolder-multiSelect button {
  width: 100%;
  border: 1px solid #ced4da;
  color: rgba(33, 33, 33, 0.75);
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: none;
  margin-bottom: 15px;
}



.modal-success .modal-content {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.modal-success .modal-header {
  border-bottom: 1px solid #badbcc;
}
.modal-success .modal-footer {
  border-top: 1px solid #badbcc;
}
@media (max-width:992px) {
  .create-folder.container .row .col{
    padding: 32px;
  }
  .create-folder .title{
    font-size: 26px;
  }
  .create-folder.container .form-check.form-switch{
    width: 100%;
  }
}