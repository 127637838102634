.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #F6971A;
    background-color: #f8f8f8;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: #F6971A;
}

.nav-tabs .nav-item {
    border-bottom: 1px solid black;
    background: #f8f8f8;
}

.nav-tabs .nav-link:hover {
    color: grey;
    background-color: #f8f8f8;
    border-top: none;
    border-left: none;
    border-right: none;
}


.nav-tabs .nav-link {
    color: black;
    background-color: #f8f8f8 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.circle {
    margin: 2px;
    background: #F6971A;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 2px;
    color: white;
    text-align: center;
    font-size: 28px;
}


.offcanvas-body-background {
    background: #f8f8f8;
}
.offcanvas-body-background-child {
    background: #f8f8f8;
    overflow-y: auto;
    height: 72vh !important;
}

.container-body {
    background-color: #fff;
    margin: 0;
    padding: 15px;
    height: auto;
}


.lead-tab-header {
    background-color: #fff;
    margin: 0;
    padding: 20px;
    height: auto;
}



.submit-btn,
.submit-btn:hover,
.submit-btn::after {
    color: white;
    background-color: #45BCD2 !important;
    border-color: #45BCD2 !important;
}


.text-color {
    color: #45BCD2;
    float: right;
}

.detailed-lead {
    /*  width: 100%; */
    height: 100vh;
    padding-top: 60px;
}

.detailed-lead .selectedAssetActions {
    margin-bottom: 0px;
    margin-top: 10px;
    background-color: #fff;
}

.detailed-lead .selectedAssetActions ul li .btn-light span {
    margin-right: 0px;
}

.detailed-lead .selectedAssetActions ul li .btn {
    padding: 0.375rem 0.375rem;
    border: rgb(233, 231, 231) 1px solid;
}

.circle1 {
    margin: 2px;
    background: #F8F8F8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    color: rgba(33, 33, 33, 0.75);
    text-align: center;
    font-size: 18px;
}

.text-break {
    width: 50px;
    line-height: 1.5em;
    height: 3em;
    /* height is 2x line-height, so two lines will display */
    overflow: hidden;
}


.btnGroup>.btn:not(:last-child):not(.dropdown-toggle) {
    width: auto;
    height: 30px;
    border: 1px solid rgb(207, 206, 206);
    font-size: 12px;
    background-color: #fff;
    color: black;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 20px;
    /*  border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; */
}

.btnGroup>.btn-check+.btn {
    width: auto;
    height: 30px;
    border: 1px solid rgb(207, 206, 206);
    font-size: 12px;
    background-color: #fff;
    color: black;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 20px;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; */
}


.btnGroup>.btn-check:checked+.btn {
    width: auto;
    border-radius: 20px;
    height: 30px;
    border: 1px solid rgb(207, 206, 206);
    font-size: 12px;
    background-color: #F6971A;
    color: #f8f8f8;
    margin-left: 2px;
    margin-right: 2px;
    /*   border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; */
}


.customDate .react-calendar-datetime-picker .input-picker .input-picker--input {
    height: 30px !important;
    width: 140px !important;
    border-radius: 50px;
}




.stage-select {
    border: none;
    color: #F6971A;
    background-image: url('../../../../../../../src/assets/dropdown2.png') !important;
}

.stage-select option {
    color: black;
}

.item-card {
    background: linear-gradient(91.69deg, #FFFFFF -25.71%, #F5F5F5 99.79%);
    padding: 0;
    border-radius: 10px;
}

.accordion,
.accordion-item {
    width: 102% !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.accordion-body {
    padding: 0 !important;
}

.accordion-button {
    font-weight: bold;
    color: rgba(33, 33, 33, 0.75);
    ;
}

.accordion-button:not(.collapsed) {
    color: rgba(33, 33, 33, 0.75);
    ;
    background-color: #fff;
    box-shadow: none;
}


.accordion-collapse .collapse .show {
    padding: 0 !important;
}

/* .react-calendar-datetime-picker .input-picker .input-picker--input {
    cursor: pointer;
    width: 180px;
    line-height: 1.625rem;
    height: 30px !important;
    border-radius: 20px;
    border-width: 0.0625rem;
}

.react-calendar-datetime-picker .input-picker .input-picker--clearBtn {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: 40%;
    right: 80px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #c9c9c9;
} */




.hours .seconds .meridien-select {
    border-radius: 40px !important;
}


.activites {
    /*  color: #ababaf; */
    width: 100%;
}

.no-data {
    color: rgba(33, 33, 33, 0.3);
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.no-data span {
    font-size: 30px;
}

.act-title {
    font-size: 14px;
}

.act-subhead {
    font-size: 12px;
    color: rgba(33, 33, 33, 0.3);
    font-weight: 600;
    line-height: 20px;
}

.act-datetime {
    font-size: 14px;
    color: #212121;
    font-weight: 500;
    line-height: 16px;
}

.act-notes {
    font-size: 14px;
    color: rgba(33, 33, 33, 0.75);
    font-weight: 500;
    line-height: 16px;
}

.comment-section {
    background-color: rgba(246, 151, 26, 0.08);
}

.comment-section>div>.act-title {
    font-size: 12px;
}

.comment-section>div>.act-notes {
    font-size: 12px;
    word-break: keep-all;
}

.act-notes-templates {
    /* width: auto;
    padding: 2px; */
    resize: horizontal;
    overflow: auto;
}

/* .popover-body{
    padding: 0 !important;
    width: auto !important;
} */

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    max-width: 350px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: #ffffff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.right-details {
    margin-left: -25px;
    margin-right: 15px;
}

.abody {
    min-height: auto;
    max-height: 250px;
    scrollbar-width: thin;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(114, 112, 112, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /*  outline: 1px solid slategrey; */
}

.colour {
    color: rgba(33, 33, 33, 0.75);
}


.sub-head {
    font-size: 18px;
    font-weight: bold;
}

.upcoming-task {
    background-color: #ecf6f8;
    width: auto;
    padding: 1;
    font-size: 13px;
    margin-left: -15px;
}