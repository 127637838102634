.crm-list-filter-container.container {
  padding: 20px 6.75rem 10px 6.75rem;
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
  margin: 0;
  max-width: 100%;
}

.crm-list-filter-container.container .btn {
  font-size: 12px;
}

.crm-list-filter-container.container .setting-btn {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
  top: 4px;
}

.stages-row {
  display: block;
  width: 2450px;
  /* overflow: auto; */
  height: 465px;
}

.col-3.stages {
  display: inline-block;
  width: 330px;
  position: relative;
  /* background-color: #d2d2d2; */
  margin-right: 20px;
  padding: 0;
  vertical-align: top;
}

.col-3 .stage-body {
  /* overflow: auto; */
  max-height: 400px;
  /* overflow-x: hidden; */
}

.crm-main-container .body-container.container {
  /* overflow: auto; */
  background-color: #f8f8f8;
  max-width: 100%;
  padding: 10px 0px !important;
}

.stage-title {
  background: rgba(246, 151, 26, 0.08);
  border-radius: 5px;
  font-weight: bold;
  color: #f6971a;
  padding: 4px 10px;
  font-size: 12px;
}

.stage-body .card {
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 8px;
  margin: 0.5rem 0;
  font-size: 14px;
  width: 312px;
}

.score-box {
  background: #f8f8f8;
  border-radius: 0px 8px;
  padding: 4px 8px;
  font-size: 11px;
  float: right;
  margin-top: -16px;
  margin-right: -16px;
}

.stage-body .card-title {
  display: inline-block;
  font-size: 14px;
}

.stage-body .card-title>div {
  display: inline-block;
  margin-right: 8px;
}

.stage-body .vr-divider {
  height: 14px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 0.8px;
}

.stage-body .vr-divider hr {
  margin: 0.4rem 0;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.stage-body hr {
  margin: 0.3rem 0;
}

.stage-body .card-title>div.sub-title {
  margin-right: 0;
  color: rgba(0, 0, 0, 0.4);
}

.stage-body .card-title .title {
  font-weight: 700;
  color: #212121;
}

.stage-body .card-title .check-box {
  font-size: 16px;
}

.stage-body .date {
  color: rgba(33, 33, 33, 0.3);
  font-size: 12px;
}

.stage-body .card-text {
  margin-bottom: 0.3rem;
}

.stage-body .footer {
  font-size: 12px;
}

.stage-body .footer div {
  font-size: 12px;
  float: left;
}

.stage-body .badge.bg-danger {
  float: right;
  background: rgba(214, 32, 28, 0.08) !important;
  border-radius: 4px;
  padding: 4px 8px;
}

.due {
  /* color: #d6201c; */
  float: right;
  font-size: 12px;
}

.load-more {
  color: rgba(33, 33, 33,0.7);
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  font-weight: 700 !important;
}

.stage-body .title {
  width: 105px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.stage-body .sub-title {
  width: 132px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.stage-body .form-check-input {
  margin-top: 0 !important;
}

.more-filters .select-box {
  width: 160px;
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
  display: block;
  margin-right: 20px;
  font-weight: 400;
  float: left;
  margin-bottom: 10px;
}

.more-filters .select-box>div:first-child {
  border-radius: 25px;
  min-height: 32px;
}

.more-filters .select-box .css-16pqwjk-indicatorContainer {
  padding: 4px 8px;
}

.more-filters .select-box .css-1thkkgx-indicatorContainer {
  padding: 0px 8px !important;
}

.more-filters .select-box .css-1szy77t-control {
  border-radius: 25px;
  min-height: 32px;
}

.more-filters .select-box .css-1szy77t-control {
  border-color: #45bcd2;
  box-shadow: 0 0 0 1px #45bcd2;
}

.more-filters .select-box .css-kj6f9i-menu {
  width: 250px;
}

.sort-by .select-box {
  width: 190px;
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
  display: block;
  margin-right: 10px;
  font-weight: 400;
  float: left;
}

.sort-by .select-box>div:first-child {
  border-radius: 25px;
  min-height: 32px;
}

.sort-by .select-box .css-16pqwjk-indicatorContainer {
  padding: 4px 8px;
}

.sort-by .select-box .css-1thkkgx-indicatorContainer {
  padding: 0px 8px !important;
}

.sort-by .select-box .css-1szy77t-control {
  border-radius: 25px;
  min-height: 32px;
}

.sort-by .select-box .css-1szy77t-control {
  border-color: #45bcd2;
  box-shadow: 0 0 0 1px #45bcd2;
}

.sort-by .select-box .css-kj6f9i-menu {
  width: 200px;
}

.no-data {
  color: rgba(33, 33, 33, 0.3);
  font-size: 14px;
  text-align: center;
}

.no-data span {
  font-size: 30px;
}

.floating-btn {
  position: absolute;
  z-index: 111;
  right: 0px;
  top: 42px;
}

.floating-btn ul>a {
  background-color: #45bcd2;
  color: #fff;
}

.floating-btn ul>li {
  color: #45bcd2;
  background-color: #fff;
}

.crm-list-filter-container .selectedAssetActions {
  margin-bottom: 0px;
  margin-top: 10px;
}

.crm-list-filter-container .selectedAssetActions ul li .btn-light span {
  margin-right: 0px;
}

.crm-list-filter-container .selectedAssetActions ul li:nth-child(2) .btn-light span {
  margin-right: 4px;
}

.crm-list-filter-container .selectedAssetActions ul li .btn {
  padding: 0.375rem 0.375rem;
}

.crm-list-filter-container .selectedAssetActions ul li:nth-child(2) .btn {
  padding: 0.375rem 0.75rem;
}

.stage-body .card.card .card-body {
  border-radius: 8px;
}

.select-box .css-1r4vtzz {
  border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: 20px;
  font-size: 12px;
  color: #000;
  font-weight: 500;
  padding: 3px 8px 3px 8px;
  width: 135px;
  background-color: transparent;
}

.select-box .css-48ayfv {
  padding: 3px 8px 3px 8px;
  border: 1px solid #ced4da;
  border-radius: 20px;
  width: 135px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  background-color: transparent;
  box-shadow: none;
}

.select-box .css-ik6y5r {
  border-radius: 4px;
  border: 1px solid #ced4da;
  box-shadow: none;
}

.select-box .css-1gpjby2 {
  opacity: 0;
}

.select-box span.plus-icon {
  position: absolute;
  margin-left: 108px;
  margin-top: -30px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.select-box .css-1v99tuv {
  opacity: 0;
}

.select-box .add-more-filter-label {
  position: absolute;
  color: #000;
  font-weight: 500;
  margin-top: 7px;
  margin-left: 12px;
}

.more-filters input.form-control {
  width: 160px;
  font-size: 12px;
  border-radius: 20px;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.setting-btn.dropdown .btn {
  font-size: 1.5em;
  top: -3px;
  position: relative;
  color: #45bcd2;
}

.setting-btn.dropdown .dropdown-toggle::after {
  display: none;
}

.setting-btn.dropdown .btn.btn-light {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.sort-by.col .select-box {
  margin-left: 25%;
}

.sort-by.col .setting-btn .dropdown-menu {
  min-width: 14rem;
  margin-top: 10px;
}

.more-filters .form-check {
  margin-bottom: 0;
}

.more-filters .form-check-input[type="radio"] {
  margin-top: 4px;
}

.more-filters .form-check-input:checked {
  background-color: #f6971a;
  border-color: #f6971a;
}


.more-filters .dropdown .btn-light {
  background-color: transparent;
  border-color: transparent;
  width: 160px;
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
  display: block;
  margin-right: 20px;
  font-weight: 400;
  float: left;
  margin-bottom: 10px;
  text-align: left;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
}

.more-filters .dropdown .dropdown-toggle::after {
  display: none;
}

.more-filters .react-calendar-datetime-picker .input-picker--input {
  font-size: 12px;
}

.more-filters .react-calendar-datetime-picker .input-picker .input-picker--clearBtn {
  width: 1rem;
  height: 1rem;
  top: 40%;
}

.clear-time {
  font-size: 18px;
  float: right;
  line-height: 0;
}

.icon-time {
  font-size: 16px;
  float: right;
  line-height: 0;
  opacity: 0.5;
}

.navbar-brand img {
  width: 135px;
}

.offcanvas-section {
  width: 35% !important;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
}

.offcanvas-body {
  background: #f8f8f8;
  padding: 0 !important;
}


.crm-search-row {
  background-color: #f8f8f8;
  max-width: 100%;
}

.wordBreak {
  word-spacing: 9999px;
}

.sip-box{
  margin-right: 5px;
}

.crm-shimmer{
  width: 95%;
  margin: 8px auto;
  border-radius: 8px;
  height: 125px;
  padding: 15px 20px;
  border: 1px solid #d1d1d1;
}

.crm-shimmer-heading{
  margin: 0px auto;
  height: 25px;
  width: 95%;
  border-radius: 4px;
  padding: 5px 10px;
  background: rgba(246, 151, 26, .08);
}


@media only screen and (min-width: 768px) {
  .crm-search-row {
    padding: 26.5px 10px
      /* ;20px 6.75rem */
      10px 6rem;
  }
}
/* @media only screen and (max-width: 768px) {
  .offcanvas.offcanvas-end{
    right:unset !important;
  }
} */