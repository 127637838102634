.header-div {
  background: #f8f8f8;
}

  .search-icon {
    position: absolute;
    opacity: 0.5;
    z-index: 111;
    right: 8px;
    top: 2px;

}

.search-icon.setup-screen {
    margin-left: 445px;
}

/* .search-title {
  display: block;
  float: left;
  font-size: 24px;
  font-weight: 400;
  margin-right: 24px;
} */

.search-field {
  width: 364px;
  float: left;
  font-style: italic;
  z-index: 3;
  position: relative;
  padding: 0.65rem 0.5rem;
  top: -5px;
}

.btn-info {
  color: #fff;
  background-color: #45bcd2;
  border-color: #45bcd2;
  font-size: 14px;
}
.search-title{
  font-weight: initial;
}
.add-new-asset-btn {
  float: right;
}

.btn-info:hover {
  color: #fff;
  background-color: #45bcd2;
  border-color: #45bcd2;
}

.add-icon {
  font-size: 22px;
  line-height: 0;
}

.asset-search-row {
  padding: 36.5px 0;
}
.custom-grid.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width:100%
}
.custom-flexx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.setup-search-row {
  padding-top: 36.5px;
  padding-bottom: 0px;
}

.crm-search-row {
  padding: 26.5px 0;
}

.setup-category ul{
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0px;
  list-style-type: none;
  display: flex;
  font-family: 'InterMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(33, 33, 33, 0.75);
}

.setup-category ul li {
  padding: 8px 15px 8px;
  /* font-size: 13px; */
  /* font-weight: 700; */
}

.setup-category ul li span{
  padding: 8px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-feature-settings: 'liga' off;
  color: rgba(33, 33, 33, 0.75);
}

.setup-category ul li span:hover {
  cursor: pointer;
}
.setup-category ul .list-active span {
  border-bottom: 2px solid #F6971A;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  font-feature-settings: 'liga' off;
  color: #F6971A !important;
}

.editFolderInput {
  float: left;
}

.editFolderBtn.btn.btn-info {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #45bcd2;
  font-size: 20px;
}

.editFolderBtn.btn.btn-info span {
  line-height: 0;
  top: -3px;
  position: relative;
}

.edit-folder {
  cursor: pointer;
}

.upload-icon {
  font-size: 16px;
  line-height: 0;
  margin-right: 8px;
}

.draft-saved {
  font-size: 14px;
  margin-right: 20px;
  display: inline-block;
  padding-top: 7px;
}

.search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.global-search-div {
  position: absolute;
  background: #fff;
  font-size: 14px;
  padding: 20px;
  z-index: 3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 350px;
  overflow: auto;
    max-width: 65.5%;
    transform: translate(-50%, 12%);
    top: 20px;
    margin-top: 0;
    width: 100%;
    right: -175px;
}

.fixed-global-search .search-title {
  color: #fff;
  z-index: 3;
  position: relative;
}

.global-search-div .title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
}

ul.suggestion-list {
  margin: 0;
  padding: 0;
}

.suggestion-list li {
  list-style: none;
  padding: 7px 5px;
  /* float: left; */
  width: 98%;
  /* background-color: #f8f8f8; */
  margin: 5px;
  /* border-radius: 5px; */
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

/* .suggestion-list li:hover {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.suggestion-list .search-icon {
  position: relative;
  z-index: 111;
  opacity: 0.5;
  margin-top: -2px;
  float: left;
  margin-right: 8px;
  margin-left: 2px;
}

.global-search-div .vr-divider {
  height: 100%;
  margin: 0 auto;
}

.search-sugg-key {
  width: 92%;
  display: inline-block;
}

.global-search-div .right-arrw {
  float: right;
  opacity: 0.5;
}

.fixed-global-search .search-icon {
  opacity: 1;
  color: #45bcd2;
  cursor: pointer;
}
.absolute-search-bar {
  position: absolute;
  top: 2px;
  right: 0;
}

.crm-header-btn-icon {
  font-size: 18px;
  line-height: 0;
  margin-right: 5px;
  top: -1px;
  position: relative;
}

.crm-col .search-icon {
  margin-left: 340px;
}

/* .fixed-global-search .search-field {
  width: 76.8%;
}

.fixed-global-search .search-icon {
  margin-left: 58%;
} */


.main-container {
  display: none;
}

.jodit-status-bar{
  visibility: hidden;
}
@media (min-width:992px) and (max-width:1200px) {
  .absolute-search-bar {
    position: absolute;
    top: 42px;
    left: 0;
}
.search-icon {
  position: relative;
  right: 23px;
}
.global-search-div{
  left: 149px;
  top: 53px;
}

}
@media (max-width:992px){
  .search-title, .sub-header{
   padding: 12px  !important;
  }
  .custom-grid.row{
    grid-template-columns: 1fr;
  }
  .absolute-search-bar {
    position: absolute;
    top: 24px !important;
    right: 0 !important;
}
  
  .global-search-div .right-arrw {
   
    position: absolute;
    top: -8px;
    right: 2px;
    font-size: 26px;
}
.assets-add-new-btn{
  margin: 10px 0px;
    margin-top: 50px;
}
.global-search-div{
  max-width: 51.5%;
  top: 25px;
}
}
@media (max-width:767px){
  .assets-add-new-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
   
}

.global-search-div {
  left: 36%;
  max-width: 65.5%;
  transform: translate(-50%, 30%);
  top: 0;
  margin-top: 0;
  width: 100%;
}
.absolute-search-bar{
  top: 57px !important;
  left: 0;
}
.absolute-search-bar .search-icon {
  position: absolute;
  opacity: 0.5;
  z-index: 111;
  right: 20px;
  top: 0;
}
  .custom-grid.row{
    grid-template-columns: 1fr;
  }
  .custom-flexx {
    flex-direction: column;
    gap:0;
    align-items: flex-start;
}
.custom-grid .search-title{
  margin-bottom: 10px !important;
}
}
@media (max-width:576px) {
  .search-field{
    width: 100%;
  }

}